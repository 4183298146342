
// en.js
export default {
 publicheader: {
	 发布: 'Release',
	 
	 收费: 'charge',
	 免费: 'free',
	 
	 专利技术: 'Patent technology',
	 
	 更新: 'update',
	 
	 提交成功: 'Submitted successfully',
	 
	 参会人员: 'Attendees',
	 请输入: 'Please enter',
	 
	 请先登录: 'Please log in first',
	 
	 
	 请输入公司名称: 'Please enter the company name',
	 请输入社会信用代码: 'Please enter the social credit code',
	 请输入公司地址: 'Please enter the company address',
	 请输入邮箱地址: 'Please enter your email address',
	 请输入联系人: 'Please enter the contact person',
	 请输入联系方式: 'Please enter your contact information',
	 请上传营业执照: 'Please upload your business license',
	 
	 
	 开通前请阅读: 'Please read before opening',
	 会员协议: 'Membership Agreement',
	 
	 
	 请上传凭证: 'Please upload the voucher',
	 
	 待支付: 'Pending payment',
	 
	 
	 确定: 'Determine',
	 去登录: 'Go login',
	 
	 新密码旧密码不一致: 'The new password and old password are inconsistent',
	 
	 格式支持: 'The format supports PNG JPG',
	 消防产品: 'Fire protection products',
	 请输入文章标题: 'Please enter the article title (2-30 words)',
	 共: 'common',
	 字: 'word',
	 发文规范: 'Standardization of Document Issuance',
	 
	 
	 
	 电子邮件: 'E-mail',
	 
	 选择模板: 'Select Template',
	 模版: 'Template',
	 下载模板: 'Download Template',
	 提交: 'Submit',
	 
	 
	 消防: 'Fire control',
	 保存资料: 'Save data',
	 确认退出登录: 'Confirm to log out and log in',
	 关闭: 'close',
	 请选择: 'Please choose',
	 发布类型: 'Publication type',
	 
	 暂无开始的活动: 'There are currently no activities starting',
	 报名数: 'Number of registrations',
	 
	 全部: 'All',
	 手机号: 'cell-phone number',
	 请输入你需要搜索的关键词: 'Please enter the keywords you need to search for',
	 北京田圆科技: 'Beijing Tianyuan Technology Co., Ltd.',
	 消防技术交流网: 'Fire Protection Technology Exchange Network',
	 登录: 'Login',
	 注册: 'sign up',
	 中文: 'Chinese',
	 英文: 'English',
	 新闻资讯: 'News and Information',
	 科技动态: 'Technology Updates',
	 产品标准: 'Product Standards',
	 新产品新技术: 'New Products and Technologies',
	 项目展示: 'Project Display',
	 需求发布: 'Demand Release',
	 技术交流: 'Technical Exchange',
	 关于我们: 'About Us',
	 社会新闻: 'Social News',
	 热点时间: 'Hot Time',
	 置顶: 'Top',
	 浏览量: 'Views',
	 服务热线: 'Service Hotline',
	 电子邮箱: 'Email',
	 关于我们: 'About Us',
	 常见问题: 'Frequently Asked Questions',
	 账号管理: 'Account management',
	 会员开通: 'Membership Activation',
	 企业认证: 'Enterprise Certification',
	 京ICP备xxxxxxxx号: 'Beijing ICP No. xxxxxxxx',
	 Copyright: 'Copyright © 2022-Now Beijing Tianyuan Technology Co., Ltd.',
	 身份选择: 'Identity Selection',
	 个人: 'Individual',
	 企业: 'Enterprise',
	 注册: 'sign up',
	 验证码: 'Verification Code',
	 重新获取: 'Re-acquire',
	 请输入密码: 'Please enter your password',
	 请再次输入密码: 'Please enter your password again',
	 已阅读并同意: 'Read and agree to the User Registration Agreement and Privacy Policy',
	 登录: 'Login',
	 已有帐号: 'Already have an account? Go to login',
	 企业信息: 'Enterprise Information',
	 基本信息: 'Basic Information',
	 公司名称: 'Company Name',
	 统一社会信用代码: 'Uniform Social Credit Code',
	 公司地址: 'Company Address',
	 邮箱地址: 'Email Address',
	 联系信息: 'Contact Information',
	 联系人: 'Contact Person',
	 联系方式: 'Contact Method',
	 联系信息: 'Contact Information',
	 
	 营业执照: 'Business License',
	 确认: 'Confirmation',
	 账号登录: 'Account Login ',
	 手机验证码登录: 'Mobile Verification Code Login',
	 你可以直接输入你的账号和密码登录: 'You can directly enter your account and password to log in',
	 请输入手机号: 'Please enter your mobile number',
	 请输入密码: 'Please enter your password',
	 忘记密码: 'Forgot your password',
	 还没帐号去注册: 'No account yet? Go to register',
	 找回密码: 'Retrieve password',
	 请输入手机号: 'Please enter your mobile number',
	 请输入验证码: 'Please enter the verification code',
	 获取验证码: 'Get verification code',
	 请输入新密码: 'Please enter your new password',
	 请确认新密码: 'Please confirm your new password',
	 验证码登录: 'Verification code login',
	 账号登录: 'Account login ',
	 开通会员享受更多权益查看更多及时资讯: 'Open a membership to enjoy more benefits and view more timely information!',
	 去开通: 'Go to open',
	 暂不: 'Not yet',
	 开通会员: 'Open a membership',
	 开通立享权益: 'Open and enjoy the benefits',
	 白金会员: 'Platinum member',
	 钻石会员: 'Diamond member',
	 至尊会员: 'Supreme member',
	 会员权益: 'Membership benefits',
	 支付方式: 'Payment method',
	 在线支付: 'Online payment',
	 线下打款: 'Offline payment',
	 支付方式扫码支付: 'Payment method: Scan code payment',
	 支付宝: 'Alipay',
	 开通前请阅读会员协议: 'Please read the "Membership Agreement" before opening',
	 请支付: 'Please pay',
	 支付成功: 'Payment successful!',
	 支付方式上传打款凭证: 'Payment method: Upload payment receipt',
	 开通前请阅读会员协议: 'Please read the "Membership Agreement" and "Automatic Renewal Rules" before opening',
	 恭喜您: 'Congratulations, the background has been reviewed and the membership has been successfully opened！',
	 我知道了: 'I understand',
	 
	 很遗憾: 'Unfortunately, the background review failed and the membership has not been opened yet You can click to view the reason!',
	 查看原因: 'View reason',
	 返回: 'Return',
	 查看更多资讯: 'View more information→',
	 浏览量: 'Views',
	 行业动态: 'Industry trends',
	 查看更多标准: 'View more standards→',
	 即将实施: 'To be implemented',
	 现行: 'Currently',
	 发布于: 'Published',
	 实施于: 'Implemented',
	 产品技术信息: 'Product technical information',
	 查看更多标准: 'View more standards→',
	 产品标题: 'Product title',
	 持有单位: 'Holding unit',
	 交易方式: 'Transaction method',
	 联系方式: 'Contact information',
	 操作: 'Operation',
	 查看详情: 'View details',
	 合作信息: 'Cooperation information',
	 查看更多信息: 'View more information→',
	 展览会议: 'Exhibitions and conferences',
	 
	 查看更多展览: 'View more exhibitions→',
	 付费: 'Payment',
	 未开始: 'Not started',
	 报名: 'Registration',
	 进行中: 'In progress',
	 已结束: 'Ended',
	 报名: 'Registration',
	 查看更多动态: 'View more trends',
	 查看更多标准: 'View more standards',
	 预览: 'Preview',
	 下载: 'Download',
	 即将实施: 'To be implemented',
	 现行: 'Currently',
	 废止: 'Abolished',
	 查看更多标准: 'View more standards',
	 友情链接名称: 'Friendly link name',
	 一级: 'Level 1',
	 二级: 'Level 2',
	 三级: 'Level 3',
	 持有单位: 'Holder',
	 交易方式: 'Transaction method',
	 报名记录: 'Registration record',
	 报名: 'Registration',
	 已报名展会: 'Registered exhibition',
	 类型: 'Type',
	 价格: 'Price',
	 状态: 'Status',
	 核销状态: 'Reconciliation status',
	 未核销: 'Not reconciled',
	 未开始: 'Not started',
	 已核销: 'Reconciled',
	 进行中: 'In progress',
	 
	 展览会议报名: 'Exhibition and conference registration',
	 参会人名称: 'Participant name',
	 请输入: 'Please enter',
	 联系方式: 'Contact information',
	 支付金额: 'Payment amount',
	 支付宝: 'Alipay',
	 微信: 'WeChat',
	 备注: 'Remarks',
	 请输入: 'Please enter',
	 取消: 'Cancel',
	 确认: 'Confirm',
	 报名成功: 'Registration successful！',
	 展览会议报名: 'Exhibition and conference registration',
	 参会人名称: 'Participant name',
	 联系方式: 'Contact information',
	 已开通: 'Already activated',
	 确认退出登录么: 'Are you sure to log out？',
	 选择分类: 'Select category',
	 发布文章: 'Publish article',
	 发文规范: 'Publishing specification',
	 请输入文章标题: 'Please enter the article title (2~30 characters)',
	 还需输入2个字: 'You need to enter 2 more characters',
	 请输入正文: 'Please enter the text',
	 添加图片: 'Add picture',
	 回到顶部: 'Back to top',
	 确认发布: 'Confirm publishing',
	 上传图片: 'Upload picture',
	 本地上传: 'Local upload',
	 厂家名称: 'Manufacturer name',
	 联系人: 'Contact person',
	 联系方式: 'Contact information',
	 电子邮箱: 'Email',
	 回到顶部: 'Back to top',
	 确认发布: 'Confirm publishing',
	 专利技术类: 'Patent technology category',
	 持有单位: 'Holder',
	 交易方式: 'Transaction method',
	 确认发布: 'Confirm publishing',
	 消息中心: 'Message center',
	 系统通知: 'System notification',
	 审核通知: 'Review notification',
	 报名通知: 'Registration notification',
	 没有更多历史消息了: 'No more historical messages',
	 返回: 'Return',
	 个人账号: 'Personal account',
	 白金会员: 'Platinum member',
	 会员权益: 'Membership rights',
	 个人中心: 'Personal center',
	 修改密码: 'Change password',
	 退出: 'Log out',
	 原密码: 'Original password',
	 修改密码: 'Change password',
	 请输入原密码: 'Please enter original password',
	 确认密码: 'Confirm password',
	 请输入原密码: 'Please enter the original password',
	 保存: 'Save',
	 个人资料: 'Personal information',
	 个人信息: 'Personal information',
	 编辑资料: 'Edit information',
	 头像: 'Avatar',
	 联系方式: 'Contact information',
	 昵称: 'Nickname',
	 统一社会信用代码: 'Unified social credit code',
	 营业执照: 'Business license',
	 关于我们: 'About us',
	 常见问题: 'FAQ',
	 账号管理: 'Account management',
	 会员开通: 'Membership activation',
	 企业认证: 'Enterprise certification',
	 确认退出登录么: 'Are you sure to log out？',
	 
	 标准检索: 'Standard Search',
	 标准类型: 'Standard type',
	 标准性质: 'Standard properties',
	 标准状态: 'Standard state',
	 请输入产品关键词: 'Please enter product keywords',
	 
	 
	 
	 
	 
	 
	 
	 
	 
	 
	 
	 
	 
	 
	 
 	message: "消息",
 	shopping: "Hi. Welcome to Thailand Mall",
 	Order: 'My Order',
 	logo: 'Thailand Shopping Mall',
 	qichepeijian: 'Auto Parts Series',
 	zhiliang: 'Survive by quality, develop by benefit',
 	hydenglu: 'Please Login',
 	youxianghao: 'Please enter your email address',
 	srmima: 'Please enter the password (6~12 letters + numbers)',
 	denglu: 'Sign in',
 	zhuce: 'Register account',
 	wjmima: 'Forgot password',
 	tongyi: 'I agree',
 	zhengce: 'User Registration Agreement" and "Privacy Policy',
 	zcxy: 'User Registration Agreement',
 	yszc: 'Privacy Policy',
 	yu: 'give',
 	zixun: 'Hotline Support',
 	jubaodh: 'Phone Reporting',
 	kuaijie: 'Quick Link',
 	gywom: "About Us",
 	lxwm: 'Contact Us',
 	yszc: 'Privacy Policy',
 	mztk: 'Disclaimer',
 	yonghuduan: 'User Application',
 	sryqm: 'Please enter the invitation code (optional)',
 	hqyzm: 'Get verification code',
 	qsrssnr: 'Enter search content',
 	sousuo: 'Search',
 	gouwuche: 'Shopping cart',
 	qbsp: 'All products',
 	shouye: 'Front Page',
 	xpzx: 'New Product Centre',
 	jlzx: 'Coupon Collection Centre',
 	lxkf: 'Contact Customer Service',
 	huidingbu: 'Back to Top',
 	cainixihuan: 'Guess you will like',
 	myorder: 'My Order',
 	ckgd: 'See More',
 	tishi: 'Hint',
 	tcdenglu: 'Are you sure to log out?？',
 	quere: 'Confirm',
 	quxiao: 'Cancel',
 	ssjieguo: 'Search Results',
 	zonghe: 'Comprehensive',
 	xiaoliang: 'Sales',
 	jiage: 'Price',
 	jgqujian: 'Price Range',
 	tjsp: 'Recommended Product',
 	spxq: 'Product details',
 	shoucang: 'Collect',
 	fenxiang: 'Share',
 	yhj: 'Coupon',
 	kuanshi: 'Style',
 	yanse: 'Colour',
 	shulaing: 'Quantity',
 	jian: 'Pieces',
 	kucun: 'In stock',
 	ljgoumai: 'Buy Now',
 	jrgouwuche: 'Add to Cart',
 	sppingjia: 'Product Review',
 	ljshiyong: 'Get Now',
 	qckeyong: 'Available',
 	youxiaoqi: 'Valid until',
 	haopinglv: 'Product Ratings：',
 	qunabu: 'All',
 	haopl: 'Good review',
 	zhongping: 'Average review',
 	chaping: 'Bad review',
 	youtu: 'With Photo',
 	guige: 'Specification',
 	pjxingji: 'Star Rating',
 	lxkefu: 'Contact Customer Service',
 	gzshijian: 'Business Time',
 	dianhua: 'Telephone',
 	youxiang: 'Email',
 	boda: 'Method',
 	fuzhi: 'Copy',
 	spxinxi: 'Product information',
 	danjia: 'Unit price',
 	shulaing: 'Quantity',
 	jine: 'Amount of money',
 	caozuo: 'Process',
 	quanxuan: 'Select all',
 	shanxhu: 'Delete',
 	yirushoucang: 'Move to favorites',
 	yxshagnp: 'Selected product',
	jian: 'piece',
 	heji: 'Total：',
 	jiesuan: 'Go to Settlement',
 	cnxh: 'Guess you will like',
 	gouwuche: 'Shopping cart',
 	jiesuan: 'Payment',
 	shouhuodizhi: 'Shipping address',
 	mydizhi: "You don't have a shipping address yet, you can go to",
 	xzdizhi: 'New shipping address',
 	qrxinxi: 'Confirm Order Information',
 	sptp: 'Product Picture',
 	spxinxi: 'Product information',
 	danjia: 'Unit price',
 	shuliang: 'Quantity',
 	xiaoji: 'Merchandise Subtotal',
 	yhj: 'Coupon',
 	xzyhj: 'Select coupon',
 	yf: 'Shipping fee',
 	hdfk: 'Cash On Delivery',
 	wuliu: 'Logistics',
 	liuyan: 'Leave Message',
 	jyliuyan: 'Recommended to leave a message before communicating',
 	spje: 'items, item amount',
 	yfje: 'To Paid',
 	tjdingdan: 'Place Order',
 	xzdz: 'New shipping address',
 	gs: 'Company',
 	mrdz: 'Default address',
 	swmr: 'Set as default',
 	binaji: 'Edit',
 	delete: 'Delete	',
 	sqdz: 'Hide Address',
 	qrdd: 'Confirm Order Information',
 	sptp: 'Product Picture',
 	spxx: 'Product information',
 	xtnr: 'Optional: fill in the content has been negotiated and confirmed with the seller',
 	tjdd: 'Place Order',
 	xinzengdizhi: 'Add new address',
 	bq: 'Address label',
 	qsrbq: 'Name this address label',
 	home: 'Home',
 	xuexiao: 'School',
 	shr: 'Receiver',
 	qsrshr: 'Fill in receiver details',
 	sir: 'Mr',
 	sister: 'Mrs./Miss',
 	lxdh: 'Contact number',
 	szdq: 'Location',
 	xxdz: 'Address',
 	sedz: 'Set as default address',
 	quxiao: 'Cancel',
 	bcdz: 'Save Address',
 	yfsm: 'Shipping instructions',
 	qrdd: 'Confirm Order',
 	tjdd: 'Place Order',
 	cjdd: 'The order is successfully created, please pay as soon as possible! Order number',
 	zfsj: 'Please pay within 30 minutes, otherwise the order will be automatically canceled',
 	xzzf: 'Select Payment Method',
 	ljzf: 'Pay Now',
 	yfje: 'To paid',
 	ddcg: 'Order paid successfully!',
 	zfje: 'Total Payment',
 	zffs: 'Payment Method',
 	zfsj: 'Payment time',
 	ckdd: 'Check order',
 	fhsy: 'Back to homepage',
 	grxx: 'Personal information',
 	tx: 'Profile picture',
 	yqm: 'Invitation code',
 	nc: 'Nickname',
 	xb: 'Gender',
 	nan: 'Male',
 	nv: 'Female',
 	shengri: 'Birthday',
 	baocun: 'Save',
 	ddzx: 'Order centre',
 	psdd: 'Shipping order',
 	wdqb: 'My Wallet',
 	qbye: 'Wallet Balance',
 	yhj: 'Coupon',
 	qtgn: 'Other functions',
 	wdshoucang: 'My Likes',
 	wdzuji: 'My History',
 	wdxx: 'My News',
 	yqhy: 'Invite friends',
 	tdtg: 'Team Promotion',
 	dzgl: 'Address Management',
 	shezhi: 'Set up',
 	xgmima: 'Change Password',
 	xgyx: 'Change zip code',
 	bzfk: 'Help and Feedback',
 	fywm: 'About Us',
 	psdd: 'Shipping order',
 	qb: 'All',
 	dfk: 'To Pay',
 	dfh: 'To Ship',
 	dsh: 'To Receive',
 	dpj: 'To Rate',
 	tk: 'Refund',
 	shouhou: 'After-sale',
 	qbrq: 'All dates',
 	spxx: 'Product information',
 	shuliang: 'Quantity',
 	spcz: 'Edit Product',
 	je: 'Amount of money',
 	zhuangtai: 'Status',
 	jycz: 'Transaction Processing',
 	ddbh: 'Order Number',
 	sqsh: 'After-sales service',
 	shifu: 'Actual Payment',
 	dfh: 'To Ship',
 	ddxq: 'Order Details',
 	txfh: 'Remind merchants of delivery',
 	qfk: 'Go to Payment',
 	qrsh: 'Confirmation of receipt',
 	ckwl: 'Shipping Checking',
 	pingjia: 'Rating',
 	tuihuo: 'Return products',
 	djihui: 'To sent back',
 	cxsq: 'Transaction Cancellation',
 	jhxx: 'Ruturn information',
 	tk: 'Refund',
 	scdd: 'Delete order',
 	grzx: 'Personal information centre',
 	gaidd: 'The order will be reserved for you for 30 minutes (from the date of placing the order). If the payment has not been made after 30 minutes, the system will automatically cancel the order.',
 	tjdd: 'Place Order',
 	fkcg: 'Payment successful',
 	ddfh: 'To Ship',
 	ddsh: 'To Receive',
 	shrxx: 'Recipient information',
 	shr: 'Recipient',
 	fkxx: 'Payment Information',
 	fkfs: 'Payment Method',
 	lxdh: 'Contact number',
 	shdz: 'Shipping address',
 	ddxx: 'Order Information',
 	ddbz: 'Order history',
 	ddbh: 'Order Number',
 	fz: 'Copy',
 	xdsj: 'Order time',
 	spje: 'item amount',
 	yfk: 'Amount to be paid',
 	xzqxyy: 'Please select a reason for cancellation',
 	qdqx: 'Confirm cancellation',
 	zbqx: 'Not Canceled',
 	zfsj: 'Payment time',
 	sqsh: 'Apply for after-sales service position',
 	shxz: 'After-sale option',
 	tkyy: 'Reason for return',
 	tkje: 'Refund amount',
 	tksm: 'Refund instructions',
 	qxz: 'Please select',
 	scpz: 'Upload certificate',
 	tj: 'Submit',
 	zczh: 'Register account',
 	qsryx: 'Please enter your email address',
 	qsryz: 'Please enter verification code',
 	hqyzm: 'Get verification code',
 	qsrmm: 'Please enter the password (6-12 letters + numbers)',
 	qsryqm: 'Please enter the invitation code (optional)',
 	yyzh: 'Already have an account?',
 	xdsj: 'Order time',
 	zfsj: 'Payment time',
 	wldh: 'Shipment number',
 	wlmc: 'Logistics name',
 	wz: 'URL',
 	dddpj: 'The order is pending evaluation, and your evaluation will be used as a reference for other buyers～',
 	cpms: 'Product Description',
 	pjms: 'Evaluation description',
 	qsrpj: 'Please enter your evaluation',
 	sctp: 'Upload image',
 	nmpk: 'Anonymous evaluation',
 	ddywc: 'The order has been completed. Wishing you happy～',
 	cxsq: 'Transaction Cancellation',
 	xgsq: 'Transaction Editing',
 	qddptcl: 'Please wait for the platform to process',
 	nycgfq: 'You have successfully initiated a refund. Please wait for the platform to process it.',
 	cxsq: 'Transaction Cancellation',
 	xgsq: 'Transaction Editing',
 	xtts: 'System hint',
 	cxsq: 'Transaction Cancellation',
 	djh: 'To sent back',
 	qjhsp: 'Please return product',
 	ptyty: 'The platform has approved the return transaction, please send back the product and submit the logistics information.',
 	jhdz: 'Ruturn information',
 	thdz: 'Return address',
 	tksm: 'Refund instructions',
 	tkxx: 'Refund information',
 	dqzt: 'Current status',
 	fwlx: 'Service type',
 	tkyy: 'Reason for return',
 	tksm: 'Refund instructions',
 	tkpz: 'Refund certificate',
 	txjhxx: 'Fill in the return information',
 	thty: 'In order to give you a better return experience within timeline, our company recommends giving priority to the collaborative express Shunfeng',
 	wldh: 'Shipment number',
 	lxdh: 'Contact number',
 	thsm: 'Return instructions',
 	tkpz: 'Refund certificate',
 	wldh: 'Shipment number',
 	wjmm: 'Forgot password',
 	qsryxh: 'Please enter your email address',
 	shywc: 'After sales completed',
 	ptytk: 'The platform has successfully refunded',
 	tkje: 'Refund amount',
 	tkzh: 'Refund account',
 	tksj: 'Refund time',
 	tksm: 'Refund instructions',
 	tkpz: 'Refund certificate',
 	wldh: 'Shipment number',
 	scdd: 'Delete order',
 	th: 'Return products',
 	shgb: 'After sales close',
 	cxsj: 'Withdrawal time',
 	nycs: 'You have withdrawn this refund transaction. If the problem is not resolved, you can re-apply transaction',
 	nycgtk: 'You have successfully applied a refund transaction, please wait patiently for the platform to process it.',
 	wdqb: 'My Wallet',
 	yuer: 'Balance (yuan)',
 	djs: 'Pending settlement (yuan)',
 	yemx: 'Balance detail',
 	qunabu: 'All',
 	srmx: 'Income record',
 	zcjl: 'Expense record',
 	djsmx: 'Pending settlement detail',
 	djslb: 'Pending settlement list',
 	djs: 'Pending settlement',
 	syje: 'Amount of income：',
 	ddbh: 'Order Number：',
 	ddje: 'Amount of order：',
 	ztxj: 'Direct promotion of subordinate Cai Xiaocai to purchase car tires, expected to receive a rebate of 10 yuan',
 	jssj: 'Settlement time',
 	jslx: 'Billing type',
 	bzsm: 'Instruction manual',
 	tixian: 'Withdraw',
 	txje: 'Withdrawal Amount',
 	zhye: 'Account Balance',
 	quanbu: 'All',
 	txsq: '1% fee for cash withdrawal',
 	txzh: 'Withdraw account',
 	qsryhk: 'Please enter the bank card number for cash withdrawal',
 	sqtx: 'Apply for withdrawal',
 	yemx: 'Balance detail',
 	nr: 'Content',
 	jiner: 'Amount of money',
 	zhuangtai: 'Status',
 	shijian: 'Time',
 	shenhezhong: 'Under review',
 	shenhebohui: 'Review rejected',
 	yidaozhang: 'Top up successfully',
 	txgz: 'Withdrawal rules',
 	tjcg: 'Submit the application successfully',
 	txzh: 'Withdraw money from account',
 	txje: 'Withdrawal Amount',
 	hydl: 'Please Login',
 	zczh: 'Register account',
 	wjmm: 'Forgot password',
 	wsy: 'Unused',
 	ygq: 'Expired',
 	qx: 'Select all',
 	qxsc: 'Cancel Favorite',
 	xl: 'Sales',
 	wdzj: 'My History',
 	shanchu: 'Delete',
 	ddxx: 'Order message',
 	ppgg: 'Platform Announcement',
 	ddyfh: 'Order shipped',
 	xtsj: 'System Upgrade Reminder',
 	ggxq: 'Announcement detail',
 	yqhy: 'Invite friends',
 	yqm: 'Invitation code',
 	fuzhi: 'Copy',
 	tdtg: 'Team Promotion',
 	ztgrs: 'Total number of promoters',
 	bydd: 'Order of the month',
 	ljdd: 'Cumulative order',
 	jrsy: 'Today Income',
 	bysy: 'This month income',
 	ljsy: 'Cumulative income',
 	qbry: 'All staff',
 	ztry: 'People who bring products to sell directly',
 	jtry: 'Intermediary',
 	gzsm: 'Rules',
 	zhitui: 'Direct sale',
 	dan: 'One',
 	ren: 'People',
 	yuan: 'Yuan',
 	zcsj: 'Registration time',
 	fxgz: 'Distribution rules',
 	tgdd: 'Promotion Order',
 	yjsy: 'Estimated income',
 	sydj: 'Income class',
 	xdsj: 'Order time',
 	ddsf: 'Actual payment amount of the order',
 	zcxy: 'Registration Agreement',
 	symx: 'Revenue Detail',
 	kssj: 'Start time',
 	jssj: 'End time',
 	dzgl: 'Address management',
 	xzdz: 'Add new address',
 	swmr: 'Set as default address',
 	xiugai: 'Change',
 	shanchu: 'Delete',
 	biaoqian: 'Address label',
 	qingshuru: 'Name this address label',
 	shr: 'Receiver',
 	qsrshr: 'Please enter the receiver name',
 	qsrlxdh: 'Please enter your phone number',
 	bjdz: 'Edit Address',
 	qdsc: 'Are you sure you want to delete this address?？',
 	xgsf: 'Modification method',
 	jmmyz: 'Change password verification',
 	yxhyzxg: 'Change email verification',
 	jiumima: 'Old password',
 	qsrjmm: 'Please enter old password',
 	xmm: 'New password',
 	qsrmm: 'Please enter new password',
 	qrxmm: 'Confirm the new password',
 	zcsrxmm: 'Please enter new password again',
 	yxyz: 'We need to verify your email address',
 	yzm: 'Verification code',
 	qsryzm: 'Please enter verification code',
 	hqyzm: 'Get verification code',
 	xmm: 'New password',
 	qsrxmm: 'Please enter new password',
 	qrxmm: 'Confirm the new password',
 	qzcsr: 'Please enter new password again',
 	zhzc: 'Register account',
 	mmcz: 'Reset Password',
 	mmxg: 'Reset Password',
 	yjfk: 'Feedback',
 	dlsb: 'Login failed',
 	zhzx: 'Account Cancellation',
 	qtwt: 'Other problems',
 	bzfk: 'Help and Feedback',
 	bzxq: 'Help Details',
 	tjsh: 'When will the submitted after-sales service be reviewed?',
 	tjyjfk: 'If you have any questions, can directly click the feedback below to submit',
 	bzfk: 'Help and Feedback',
 	fklx: 'Feedback type',
 	cpjy: 'Product suggestion',
 	gngz: 'Problem reports',
 	qtwt: 'Other problems',
 	fkwt: 'Feedback record',
 	wtms: 'Problem Description',
 	wtxxms: 'The more detailed, the faster we can solve the problem for you',
 	tjfk: 'Submit Feedback',
 	fksj: 'Feedback time',
 	pthf: 'Platform replies',
 	gywm: 'About Us',
 	cnxh: 'Guess you will like',
 	
 	
 	yfk: 'Received',
 	ywc: 'Completed',
 	ksrq: 'Start date',
 	jsrq: 'End date',
 	shuoming: 'illustrate',
 	zhi: 'to',
 	jjsy: 'Indirect benefits',
 	shwc: 'After sales completion',
 	tcdl: 'Log out of login',
 	qdtc: 'Are you sure you want to log out?',
 	qd: 'determine',
 	qx: 'cancellation',
 	linjuan: 'collect coupons',
 	xzcm: 'Choose a size',
 	ljsy: 'immediate use',
 	man: 'full',
 	kejian: 'Reducible',
 	ddh: 'Order No.',
 	jjcg: 'Successful trade',
 	ddyqx: 'Order cancelled',
 	sy: 'residue',
 	fk: 'payment',
 	qxdd: 'cancellation of order',
 	ytx: 'Reminded',
 	ckph: 'The warehouse is preparing goods, please be patient and wait',
 	zzps: 'The product you purchased is currently being delivered. Please be patient and wait。',
 	ddpjck: 'The order is pending evaluation, and your evaluation will be given to other buyers for reference。',
 	znyk: 'The order has been completed. Wishing you a pleasant life。',
 	yfk: 'paid',
 	yfh: 'Shipped',
 	ywc:'Completed',
 	ypj: 'Evaluated',
 	shz: 'After sales',
 	shwc: 'After sales completion',
 	yqx: 'Canceled',
 	yf: 'freight',
 	sfk: 'Disbursements',
 	wlgs: 'logistics company',
 	qsrwlgs: 'Please enter the logistics company',
 	qtxwldh: 'Please fill in the logistics tracking number',
 	qsrxt: 'Please enter (optional)',
 	cxsqcg: 'Successfully revoked the application',
 	dsh: 'Audit',
 	djh: 'To be returned',
 	dtk: 'To be refunded',
 	shywc: 'After sales completed',
 	shgb:'After sales shutdown',
 	shbh: 'Approval rejected',
 	qddtk: 'Please wait for the platform to refund',
 	ptjjtk: 'The platform has sent it back. Please wait for the platform to receive the goods and refund',
 	ptcxcl: 'After the platform agrees, please return the goods according to the provided return address and fill in the return waybill number for submission. If the platform refuses, you can modify the application and initiate it again, and the platform will process it again。',
 	ptxsyz: 'Please do not use collect payment or surface mail without consensus on the platform to avoid rejection. Please fill in the true return logistics information. If not filled in within the deadline, the return application will be automatically closed。',
     ptxz: 'If the platform receives the goods and verifies them to be correct, a refund will be issued to you. The money will be returned to the account you selected for payment via the original route。',
 	bccx: 'You have withdrawn this refund request. If there are any unresolved issues, you can initiate a new application。',
 	tjsq: 'Submit application',
 	ptsh: 'Platform review',
 	jhsp: 'Returning goods',
 	ytk: 'Refunded',
 	jhdz: 'Return address',
 	qbzwz: 'Please ensure that the goods are complete and without any issues. We will refund you immediately upon receipt of the goods',
	zwjt: 'Chinese',
	yw: 'English',
	ynw: 'vietnamese',
	tw: 'Thai',
	ylq: 'Received',
	xyx: 'New email',
	qsrxxx: 'Please enter a new email address',
	
	yijs: 'Settled',
	yjhdfy: 'Expected to receive commission refund',
	wjs: 'Not calculated',
	
	qscyyt: 'Please upload screenshots of the application, up to 6',
	cxsq: 'reapply',
	fbsj: 'Thời gian phát hành',
	qbsc: 'delete all',
	qsrzyx: 'Please enter the correct email number',
	qgxyhxy: 'Chọn User Agreement',
	qsrzqyyh: 'Please enter the correct account and password'
	
	
 	
  }
}
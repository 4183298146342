<template>
	<div @click="topage()">
		<el-container>
			<el-header style="height: 100px;">
				<!-- @mousedown="test" -->
				<div style="height: 80px;width: 100%;background: #FFFFFF;align-items: center;">
					<div style="display: flex;justify-content: space-around;height: 80px;">
						<div @click.stop="toshouye()" class="" style="display: flex;width: 38%;margin-top: 10px;">
							<img style="width: 60px;height: 60px;" src="../image/logo.png" alt="" />
							<div style=" height: 60px;">
								<div @click="changeLang(item)" style="margin-top: 6px;">
									<div class=""
										style="margin-left: 6px;margin-right: 6px; height: 30px; line-height: 30px;font-size: 28px!important;">
										{{$t('publicheader.北京田圆科技')}}
									</div>
									<div class=""
										style="margin-left: 6px;margin-right: 6px;line-height: 30px;font-size: 14px;">
										{{$t('publicheader.消防技术交流网')}}
									</div>
								</div>
							</div>
						</div>
						<div style="display: flex;position: relative;margin-top: 25px;" v-if="token">
							<div style="width: 240px;
height: 29px;
border-radius: 4px 4px 4px 4px;
border: 1px solid #AAAAAA;line-height: 29px;display: flex;align-items: center;margin-right: 12px;" @click="tosousuo()">

								<input type="text"
									style="border: none;width: 180px;margin-left: 10px;margin-top: 0px;height: 29px;"
									:placeholder="$t('publicheader.请输入你需要搜索的关键词')" />

								<img src="../image/sousuo.png" alt=""
									style="width: 20px;height: 20px;padding-top: 0px;margin-right: 20px;" />
							</div>
							<!-- 			<img src="../image/sousuo.png" alt=""
								style="width: 20px;height: 20px;margin-top: 6px;margin-right: 20px;" /> -->
							<div @click.stop="tokaitong()" v-if="userlevel_id==1&&user_group=='user'" style="width: 84px;
height: 29px;
background: #FFB202;
border-radius: 15px 15px 15px 15px;line-height: 29px;font-size: 14px;text-align: center;color: #fff;">
								{{$t('publicheader.开通会员')}}
							</div>
							<img src="../image/16.png" alt="" @click.stop="tofabu()"
								style="width: 20px;height: 20px;margin-top: 6px;margin-right: 10px;margin-left: 20px;" />
							<div @click.stop="tofabu()" style="font-size: 14px;color: #333;width: 50px;
height: 19px;line-height: 28px;">{{$t('publicheader.发布')}}</div>
							<div style="line-height: 20px;position: relative;" @click="toxiaoxi()">
								<img src="../image/15.png" alt=""
									style="width: 20px;height: 20px;margin-top: 6px;margin-right: 10px;margin-left: 10px;" />
								<div v-if="number" style="width: 12px;position: absolute;left: 20px;top: 0px;
height: 12px;
background: #FE2B38;border-radius: 50px;font-size: 10px;text-align: center;line-height: 12px;color: #fff;">{{number}}
								</div>
							</div>
							<div style="line-height: 20px;position: relative;" @click.stop="togeren()">
								<img style="width:32px;height: 32px;border-radius: 50%;" :src="head_img" alt="" />
								<img v-if="userlevel_id>1"
									style="width:24px;height: 24px;position: absolute;left: 5px;top: -16px;"
									src="../image/19.png" alt="" />
							</div>
							<div @click.stop="togeren()" style="font-size: 14px;color: #333;
							height: 19px;line-height: 28px;margin-left: 10px;">{{user_name}}</div>
							<img @click.stop="togeren()"
								style="width:6px;height: 6px;margin-top: 10px;margin-left: 6px;" src="../image/18.png"
								alt="" />
							<img src="../image/zhongwen.png" alt="" @click.stop="toqiehuan()"
								style="width: 20px;height: 20px;margin-top: 6px;margin-left: 20px;" />
							<span @click.stop="toqiehuan()"
								style="color: rgba(178, 13, 1, 1);line-height: 29px;margin-left: 12px;font-size: 14px;">{{$t('publicheader.zwjt')}}</span>
							<div v-if="yystatus"
								style="margin-top: 120px;position: absolute;left: 510px;width: 100px; height: 60px;top: -80px;z-index: 300;background-color: #ffffff;">
								<div v-for="(item,index) in yyList" :key="index" @click="changeLang(item)">
									<div style="display: flex;align-items: center;">
										<div class="header_yy" :class="{'header_yys':yyindex==index}"
											@mousemove="yyMouse(index)"
											style="margin-left: 6px;margin-right: 6px;display: flex;line-height: 30px;">
											<img v-if="item.id==1" src="../image/zhongwen.png" alt=""
												style="width: 20px;height: 20px;margin-top: 6px;margin-left: 6px;" />
											<img v-if="item.id==2" src="../image/yinwen.png" alt=""
												style="width: 20px;height: 20px;margin-top: 6px;margin-left: 6px;" />
											<span style="margin-left: 6px;">{{$t(item.yuyan)}}</span>
										</div>
									</div>
								</div>
							</div>
							<div style="margin-top: 120px;position: absolute;left: 360px;top: -80px;z-index: 300;background-color: #ffffff;width: 334px;
height: 231px;
background: #FFFFFF;
box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
border-radius: 4px 4px 4px 4px;" v-if="status==2">

								<div id=""
									style="display: flex;align-items: center;justify-content: space-between;height: 10px;width: 320px;">
									<div></div>
									<img src="../image/46.png"
										style="width: 12px;height: 12px;padding-left: 22px;padding-top: 22px;" alt="" />

								</div>
								<div
									style="display: flex;align-items: center;justify-content: space-between;margin-top: 0px;">
									<div style="display: flex;align-items: center;">
										<img :src="head_img" style="width: 42px;height: 42px;margin-left: 20px;"
											alt="" />
										<div style=" height: 60px;margin-left: 6px;">
											<div style="margin-top: 6px;">
												<div class=""
													style="margin-left: 6px;margin-right: 6px; height: 20px; line-height: 30px;font-size: 14px!important;color: #000;">
													{{user_name}}
												</div>
												<div class=""
													style="margin-left: 6px;margin-right: 6px;line-height: 20px;font-size: 14px;font-size: 12px;color: rgba(170, 170, 170, 1);margin-top: 5px;">
													ID：{{user_id}}</div>
											</div>
										</div>
									</div>
									<div style="font-size: 12px;color: rgba(178, 13, 1, 1);margin-right: 20px;">
										{{$t('publicheader.个人账号')}}
									</div>
								</div>
								<div style="width: 294px;
								height: 92px;
								background: rgba(255,151,70,0.1);
								border-radius: 8px 8px 8px 8px;margin-left: 20px;">
									<div style="color: rgba(124, 162, 204, 1);font-size: 16px;">
										<div style="margin-left: 16px;">{{userlevel_name}}</div>
										<div v-if="user_group=='user'"
											style="display: flex;align-items: center;color: rgba(255, 178, 2, 1);font-size: 14px;line-height: 20px;margin-left: 16px;">
											<div @click.stop="toquanyi()">{{$t('publicheader.会员权益')}}</div>

										</div>

										<div v-else
											style="display: flex;align-items: center;color: rgba(255, 178, 2, 1);font-size: 14px;line-height: 20px;margin-left: 16px;">
											<div style="height:16px" @click.stop="toquanyi()"></div>

										</div>
									</div>
									<div
										style="display: flex;align-items: center;color:rgba(51, 51, 51, 1);font-size: 14px;margin-left: 16px;">
										<div @click="totuichu()">{{$t('publicheader.退出')}}</div>
										<!-- <div style="margin-left: 16px;">｜</div> -->
										<div @click="togerenzhongxin()" style="margin-left: 42px;">
											{{$t('publicheader.个人中心')}}
										</div>
										<!-- <div style="margin-left: 16px;">｜</div> -->
										<div @click="toxiugai()" style="margin-left: 42px;">{{$t('publicheader.修改密码')}}
										</div>

									</div>
								</div>
							</div>
						</div>
						<div style="display: flex;position: relative;margin-top: 25px;" v-if="!token">
							<img @click="tosousuo()" src="../image/sousuo.png" alt=""
								style="width: 20px;height: 20px;margin-top: 6px;" />
							<div style="width: 56px;
						height: 29px;
						background: #FFFFFF;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #AAAAAA;font-size: 14px;text-align: center;line-height: 29px;margin-left: 20px;"
								@click="todenglu(1)">{{$t('publicheader.登录')}}</div>
							<div style="width: 56px;
						height: 29px;
						background: #FFFFFF;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #AAAAAA;font-size: 14px;text-align: center;line-height: 29px;margin-left: 20px;
						    overflow: hidden;
						    -webkit-line-clamp: 1;
						    text-overflow: ellipsis;
						    display: -webkit-box;
						    -webkit-box-orient: vertical;
						
						" @click="todenglu(3)">{{$t('publicheader.注册')}}</div>
							<img src="../image/zhongwen.png" alt="" @click="toqiehuan()"
								style="width: 20px;height: 20px;margin-top: 6px;margin-left: 20px;" />
							<span @click="toqiehuan()"
								style="color: rgba(178, 13, 1, 1);line-height: 29px;margin-left: 12px;font-size: 14px;">{{$t('publicheader.中文')}}</span>
							<div v-if="yystatus"
								style="margin-top: 120px;position: absolute;left: 155px;width: 100px; height: 60px;top: -80px;z-index: 300;background-color: #ffffff;">
								<div v-for="(item,index) in yyList" :key="index" @click="changeLang(item)">
									<div style="display: flex;align-items: center;">
										<div class="header_yy" :class="{'header_yys':yyindex==index}"
											@mousemove="yyMouse(index)"
											style="margin-left: 6px;margin-right: 6px;display: flex;line-height: 30px;">
											<img v-if="item.id==1" src="../image/zhongwen.png" alt=""
												style="width: 20px;height: 20px;margin-top: 6px;margin-left: 6px;" />
											<img v-if="item.id==2" src="../image/yinwen.png" alt=""
												style="width: 20px;height: 20px;margin-top: 6px;margin-left: 6px;" />
											<span style="margin-left: 6px;">{{$t(item.yuyan)}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</el-header>
		</el-container>
		<el-dialog :title="$t('publicheader.tishi')" :visible.sync="dlstatus" width="460px" :before-close="handleClose">

			<div style="height:230px" id="">

				<img src="../image/40.png"
					style="width: 86px;height: 76px;margin: 0 auto;margin-left: 162px;margin-top: 8px;" alt="" />
				<div style="text-align: center;margin-top: 32px;">

					{{$t('publicheader.确认退出登录')}}

				</div>
				<div style="display: flex;align-items: center;margin-left: 72px;margin-top: 50px;">

					<div @click="toqueding()" style="width: 128px;
height: 48px;
background: #AAAAAA;
border-radius: 0px 0px 0px 0px;text-align: center;line-height: 48px;color: #fff;">
						{{$t('publicheader.确认')}}
					</div>
					<div @click="dlstatus = false" style="width: 128px;
height: 48px;
background: #B20D01;
border-radius: 0px 0px 0px 0px;text-align: center;line-height: 48px;color: #fff;margin-left: 40px;">
						{{$t('publicheader.取消')}}
					</div>
				</div>

			</div>


			<!-- <span>{{$t('publicheader.qdtc')}}</span>
			<span slot="footer" class="dialog-footer">
				<el-button style="background-color: #FF6C34;color: #fff;" type=""
					@click="toqueding()">{{$t('publicheader.qd')}}
				</el-button>
				<el-button style="border: 1px solid #FF6C34;background-color: #FFECE5;color: #FF6C34;"
					@click="dialogVisible = false">{{$t('publicheader.qx')}}</el-button>
			</span> -->
		</el-dialog>


		<el-dialog :visible.sync="xgstatus" width="529px" :before-close="handleClose">

			<div style="
height: 336px;
background: #FFFFFF;
border-radius: 8px 8px 8px 8px;" id="">

				<div id="" style="margin-top: -32px;">
					{{$t('publicheader.修改密码')}}
				</div>
				<div id="" style="display: flex;align-items: center;margin-left: 94px;margin-top: 50px;">

					<div style="width: 70px;">{{$t('publicheader.手机号')}}：</div>
					<div style="width: 260px;
height: 39px;
background: #FFFFFF;
border-radius: 0px 0px 0px 0px;
border: 1px solid #AAAAAA;line-height: 39px;">
						<input v-model="mobile" disabled onkeyup="this.value=this.value.replace(/[^\w]/g,'')"
							:placeholder="$t('publicheader.请输入手机号')" style="border: none;margin-left: 12px;"
							type="number" />
					</div>
				</div>
				<div id="" style="display: flex;align-items: center;margin-left: 94px;margin-top: 20px;">

					<div style="width: 70px;">{{$t('publicheader.验证码')}}：</div>
					<div style="width: 260px;
				height: 39px;
				background: #FFFFFF;
				border-radius: 0px 0px 0px 0px;
				border: 1px solid #AAAAAA;line-height: 39px;display: flex;align-items: center;">
						<input v-model="password" onkeyup="this.value=this.value.replace(/[^\w]/g,'')"
							style="border: none;margin-left: 12px;" :placeholder="$t('publicheader.请输入验证码')"
							type="password" />

						<div class="float_right container-code" style="color: rgba(255, 178, 2, 1);width: 80px;height:30px; overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;" @click="changemimacodes" v-if="codetrue">

							{{$t('publicheader.hqyzm')}}
						</div>
						<div class="float_right container-code" style="color: #333" v-else>
							{{ time }}s
						</div>
					</div>
				</div>
				<div id="" style="display: flex;align-items: center;margin-left: 90px;margin-top: 20px;">

					<div style="width: 70px;">{{$t('publicheader.确认密码')}}：</div>
					<div style="width: 260px;
								height: 39px;
								background: #FFFFFF;
								border-radius: 0px 0px 0px 0px;
								border: 1px solid #AAAAAA;line-height: 39px;">
						<input v-model="password_code" onkeyup="this.value=this.value.replace(/[^\w]/g,'')"
							style="border: none;margin-left: 12px;" :placeholder="$t('publicheader.请输入密码')"
							type="password" />
					</div>
				</div>


				<!-- <div class="container-one" style="margin-top: 50px;">
				<div class="clearfix container-box-one">
					<img class="float_left container-icon" src="../image/6.png" alt="" />
					<input class="float_left container-input" onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
						v-model="user_mobile" type="text" placeholder="请输入手机号" />
				</div>
				<div class="clearfix container-box-one m-t-xs" style="margin-top: 24px;">
					<img class="float_left container-icon" src="../image/7.png" alt="" />
					<input class="float_left container-input"
						onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" v-model="newcode"
						maxlength="6" type="text" :placeholder="$t('publicheader.qsryz')" />
					<div class="float_right container-code" style="color: rgba(255, 178, 2, 1);"
						@click="changemimacodes" v-if="codetrue">

						{{$t('publicheader.hqyzm')}}
					</div>
					<div class="float_right container-code" style="color: #333" v-else>
						{{ time }}s
					</div>
				</div>
			</div>
 -->

				<div style="display: flex;align-items: center;justify-content: space-between;margin-top: 40px;">
					<div></div>
					<div style="display: flex;align-items: center;">

						<div @click="tosguanbi" style="width: 72px;
height: 38px;
border-radius: 0px 0px 0px 0px;
border: 1px solid #AAAAAA;text-align: center;line-height: 38px;margin-right: 20px;">{{$t('publicheader.关闭')}}</div>
						<div style="width: 72px;
height: 38px;
background: #B20D01;
border-radius: 0px 0px 0px 0px;text-align: center;line-height: 38px;color: #fff;" @click="tobaocun()">
							{{$t('publicheader.保存')}}
						</div>

					</div>

				</div>

			</div>
		</el-dialog>



		<el-dialog :title="$t('publicheader.tishi')" :visible.sync="fabustatus" width="460px"
			:before-close="handleClose">

			<div style="height:230px" id="">

				<img src="../image/40.png"
					style="width: 86px;height: 76px;margin: 0 auto;margin-left: 162px;margin-top: 8px;" alt="" />
				<div style="text-align: center;margin-top: 32px;">
					{{$t('publicheader.确认退出登录')}}

				</div>
				<div style="display: flex;align-items: center;margin-left: 72px;margin-top: 50px;">

					<div @click="toqueding()" style="width: 128px;
		height: 48px;
		background: #AAAAAA;
		border-radius: 0px 0px 0px 0px;text-align: center;line-height: 48px;color: #fff;">
						{{$t('publicheader.确认')}}
					</div>
					<div @click="dlstatus = false" style="width: 128px;
		height: 48px;
		background: #B20D01;
		border-radius: 0px 0px 0px 0px;text-align: center;line-height: 48px;color: #fff;margin-left: 40px;">
						{{$t('publicheader.取消')}}
					</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="fbstatus" width="429px" :before-close="handleClose">
			<div style="
		height: 236px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;display: flex;" id="">
				<div id="" style="margin-top: 32px;margin-left: 20px;">
					{{$t('publicheader.发布类型')}}
				</div>
				<el-select v-model="value" :placeholder="$t('publicheader.请选择')"
					style="margin-top: 18px;margin-left: 20px;height: 20px;">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div style="display: flex;align-items: center;justify-content: space-between;margin-top: -20px;">
				<div></div>
				<div style="display: flex;align-items: center;">
					<div style="width: 72px;
			height: 38px;
			border-radius: 0px 0px 0px 0px;
			border: 1px solid #AAAAAA;text-align: center;line-height: 38px;margin-right: 20px;" @click="fbstatus=false">
						{{$t('publicheader.关闭')}}
					</div>
					<div style="width: 72px;
			height: 38px;
			background: #B20D01;
			border-radius: 0px 0px 0px 0px;text-align: center;line-height: 38px;color: #fff;" @click="toqdfabu()">
						{{$t('publicheader.确定')}}
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: ['messagess'],
		data() {
			return {
				status: 1,
				time: 60,
				yyxianshi: '',
				timer: '',
				xgstatus: false,
				user_info: '', //用户信息
				location_city: "", //定位城市
				pageurl: "", //获取的当前页面路径
				headerIndex: 0,
				userlevel_name: '',
				fbstatus: false,
				options: [{
					value: 1,
					label: '消防产品类'
				}, {
					value: 2,
					label: '专利技术类'
				}, {
					value: 3,
					label: '产品技术类'
				}],
				value: '',
				list: [{
					name: 'publicheader.denglu',
					id: 1
				}, {
					name: 'publicheader.zhuce',
					id: 2
				}],
				// list: [
				// 	{
				// 		name: $t('publicheader.denglu')
				// 	},
				// 	{
				// 		name: $t('publicheader.denglu')
				// 	}
				// ],
				// yyList: ['中文简体', '英语', '韩语'],
				yyList: [{
						yuyan: 'publicheader.zwjt',
						id: 1,
						image: '../image/zhongwen.png'
					},
					{
						yuyan: 'publicheader.yw',
						id: 2,
						image: '../image/zhongwen.png'
					},
					// {
					// 	yuyan: 'publicheader.ynw',
					// 	id: 3
					// },
					// {
					// 	yuyan: 'publicheader.tw',
					// 	id: 4
					// }
				],
				yuyans: 'publicheader.zwjt',
				yystatus: false,
				codetrue: true,
				yyindex: 0,
				lsstatus: false,
				ncstatus: 1,
				dlstatus: false,
				token: '',
				gwNumber: '',
				keywords: '',
				head_img: '',
				user_name: '',
				lsList: [],
				security_code: '',
				password: '',
				password_code: '',
				value: '',
				user_id: '',
				number: '',
				userlevel_id: '',
				number: '',
				user_group: '',
				mobile: '',
				time: 60,
				timer: '',

			}
		},
		created() {
			this.pageurl = (window.location.href).split("#")[1];
			this.yyxianshi = localStorage.getItem('lang')
			console.log('这是英文', localStorage.getItem('lang'));
			// if (localStorage.getItem('userinfo')) {
			// 	this.user_info = JSON.parse(localStorage.getItem('userinfo'))
			// } else {
			// 	this.user_info = ''
			// }
			// if (localStorage.getItem('logtype', 1)) {
			// 	localStorage.removeItem("logtype");
			// 	window.location.reload();
			// }
			// if (localStorage.getItem('location_city')) {
			// 	this.location_city = localStorage.getItem('location_city');
			// }

			// console.log(this.token, "----------");
		},
		mounted() {
			console.log(JSON.parse(localStorage.getItem('userinfo')), 'ppp');
			this.token = JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo'))
				.user_token : '';
			if (this.token) {

				this.getuser()
				this.getlishi()
				this.getgwnumber()
			}
			// document.addEventListener("click", this.bodyCloseMenus);
		},
		beforeDestroy() {
			// document.removeEventListener("click", this.bodyCloseMenus);
		},
		methods: {

			changemimacodes() {
				console.log('@@！！！');
				// var testphone = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
				var testphone = /^1\d{10}$/;
				if (!testphone.test(this.mobile)) {
					this.$message.error('请输正确入手机号');
				} else {
					this.$http({
						url: '/m7019/5b5bdc44796e8',
						method: 'post',
						notoken: true,
						data: JSON.stringify({
							mobile: this.mobile,
							type: 2,
							// event: 'register',
							is_test: "1"
						}),
					}).then((res) => {
						if (res.data.code == 1) {
							this.codetrue = false;
							(this.timer = setInterval(() => {
								this.time--
								if (this.time == 1) {
									this.time = 60;
									this.codetrue = true
									clearTimeout(this.timer)
								}
							}, 1000))

							this.$message({
								type: "success",
								message: res.data.data.code
							})
						} else {
							this.$message({
								type: "error",
								message: res.data.msg
							})
						}
					})
				}
			},


			tosguanbi() {


				this.xgstatus = false

			},

			toquanyi() {

				this.$router.push({
					name: 'huiyuan',
				})

			},

			topage() {
				if (this.status == 2) {
					this.status = ''
				}

			},
			toshouye() {
				this.$router.push({
					name: 'home',
				})
			},
			tosousuo() {
				this.$router.push({
					name: 'sousuojieguo',
				})
			},

			tokaitong() {
				this.$router.push({
					name: 'yikaitonghuiyuan',
				})
			},

			toqdfabu() {
				console.log(this.value, '@@@@');
				if (!this.value) return this.$message.error('请选择发布类型');
				this.fbstatus = false

				if (this.value == 3) {

					this.$router.push({
						name: 'fabuwd',
						query: {
							type: this.value
						}
					})
				} else {
					this.$router.push({
						name: 'fabu',
						query: {
							type: this.value
						}
					})
				}


			},
			toxiugai() {
				this.xgstatus = true
			},

			tofabu() {
				this.fbstatus = true
			},

			tokaitong() {
				this.$router.push({
					name: 'huiyuan',
				})
			},

			tobaocun() {
				if (!this.mobile) return this.$message.error('请输入原密码');
				if (!this.password) return this.$message.error('请输入验证码');
				if (!this.password_code) return this.$message.error('请输入新密码');
				this.$http({
					url: '/m7019/5caeeba9866aa',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						account: this.mobile,
						code: this.password,
						password: this.password_code,
						account_type: 'mobile',
					}),
				}).then((res) => {
					if (res.data.code == 1) {
						localStorage.clear();
						this.xgstatus = false
						this.$message({
							type: 'success',
							message: '修改成功!',
						})
						localStorage.setItem('logtype', 1)
						localStorage.setItem("headerIndex", 0)
						if (this.pageurl == '/') {
							window.location.reload();
						} else {
							this.$router.push({
								name: 'home'
							})
						}


					} else {
						this.$message.error(res.data.msg)
					}
				})
			},

			toxiaoxi() {

				this.$router.push({
					name: 'xiaoxizhongxin',
				})
			},

			toqiehuan() {
				// this.$message.error('缺少三方数据')
				// return


				this.yystatus = !this.yystatus

			},

			togerenzhongxin() {
				console.log('@@@');
				this.$router.push({
					name: 'gerenziliao',
				})

			},


			bodyCloseMenus(e) {

				let self = this;
				if (self.lsstatus == true) {
					self.lsstatus = false;
				}
			},
			test(event) {
				console.log(event, '；；；');
				if (event.button == 0) {
					this.lsstatus = false
				} else if (event.button == 1) {

				} else if (event.button == 2) {
					this.lsstatus = false
				}

			},

			qbdetail() {
				console.log('全部');
				this.$http({
					url: '/m2958/642e5c1405363',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						history_id: ''
					}),
				}).then((res) => {
					if (res.data.code == 1) {
						this.$message.success(res.data.msg)
						this.keywords = '';
						this.getlishi()
					} else {
						this.$message.error(res.data.msg)
					}
				})


			},

			todelete(id) {
				this.$http({
					url: '/m2958/642e5c1405363',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						history_id: id
					}),
				}).then((res) => {
					if (res.data.code == 1) {
						this.$message.success(res.data.msg)
						this.getlishi()

					} else {
						this.$message.error(res.data.msg)


					}
				})

			},


			// 获取历史记录
			getlishi() {
				this.$http({
					url: '/m2958/642e5a97d57f0',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({

					}),
				}).then((res) => {
					if (res.data.code == 1) {
						this.lsList = res.data.data

					} else {

					}
				})
			},

			getuser() {
				this.$http({
					url: '/m7019/5c78c4772da97',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({

					}),
				}).then((res) => {
					if (res.data.code == 1) {
						this.head_img = res.data.data.avatar;

						this.user_name = res.data.data.nickname;

						this.user_id = res.data.data.id
						this.userlevel_id = res.data.data.userlevel_id
						this.userlevel_name = res.data.data.userlevel_name

						this.user_group = res.data.data.user_group
						this.mobile = res.data.data._mobile

					} else {

					}
				})
			},

			todingdan() {
				if (!this.token) {
					this.$message.error('请先登录');
					return
				}
				this.$router.push({
					name: 'gerenzhongxin',
				})
			},

			toziliao() {
				if (!this.token) {
					this.$message.error('请先登录');
					return
				}
				this.$router.push({
					name: 'ziliao',
				})
			},

			// tosousuo(item, status) {
			// 	console.log(item, '分类');
			// 	this.$emit('change', item);
			// 	this.lsstatus = false;
			// 	if (status == 1) {
			// 		this.getlishi()
			// 	} else {
			// 		this.$router.push({
			// 			name: 'feilei'

			// 		})
			// 	}
			// 	this.keywords = ''
			// },
			tofeilei() {
				console.log('分类');
				this.$router.push({
					name: 'feilei'

				})
			},
			// 获取购物车数量
			getgwnumber() {
				this.$http({
					url: '/m7019/64241ca6cf066',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({

					}),
				}).then((res) => {
					if (res.data.code == 1) {
						this.number = res.data.data.all
						console.log(this.messagess, "pppppppp");

					} else {


					}
				})

			},

			handleClose(done) {

				done();

			},
			// 鼠标移入事件
			headerMouse(index) {
				this.headerIndex = index

				console.log(this.headerIndex);
			},

			todenglu(item) {
				console.log(item);
				if (item == 1) {
					this.$router.push({
						name: 'LoginIdex',
					})
				} else {
					this.$router.push(
						'LoginIdex?isLogin=8'
					)
				}
			},

			togouwuche() {
				if (!this.token) {
					this.$message.error('请先登录');
					this.$router.push({
						name: 'LoginIdex',
					})
					return
				}
				this.$router.push({
					name: 'gouwuche',
				})
			},
			yyMouse(index) {
				this.yyindex = index
			},
			// 切换语言
			toyyopen() {
				// this.$message.success('缺少第三方资料');
				// return;
				console.log('打开了');
				this.yystatus = !this.yystatus;
			},
			changeLang(item) {
				console.log(item.id, '到这了');
				this.language = item.id
				if (this.language == 1) {
					localStorage.setItem('lang', 'zn')
					this.$i18n.locale = localStorage.getItem('lang')
					console.log('这是中文', localStorage.setItem('lang', 'zn'));
				} else if (this.language == 2) {
					console.log('这是英文', localStorage.setItem('lang', 'en'));
					localStorage.setItem('lang', 'en')
					console.log('这是英文', localStorage.getItem('lang'));
					this.$i18n.locale = localStorage.getItem('lang') // 设置当前语言
				}

				this.yuyans = item.yuyan;
				this.yystatus = false;
				window.location.reload();

			},

			guanbi() {
				console.log('zoi;e');
				this.lsstatus = false
			},
			//跳转首页
			gohotData() {
				localStorage.setItem('headerIndex', 0)
				this.$router.push('/')
			},

			//跳转登录
			gologin() {

			},

			toqueding() {
				this.dlstatus = false
				localStorage.clear();
				this.$message({
					type: 'success',
					message: '退出成功!',
				})
				localStorage.setItem('logtype', 1)
				localStorage.setItem("headerIndex", 0)
				if (this.pageurl == '/') {
					window.location.reload();
				} else {
					this.$router.push({
						name: 'home'
					})
				}

			},

			//退出登录
			clearlogin() {
				this.$confirm('是否退出该账号?', '提示', {
						confirmButtonText: '确定',
						confirmButtonClass: 'del-model',
						cancelButtonText: '取消',
						center: true,
					}).then(() => {

						localStorage.setItem('logtype', 1)
						this.$message({
							type: 'success',
							message: '退出成功!',
						})
						localStorage.setItem("headerIndex", 0);
						if (this.pageurl == '/') {
							window.location.reload();
						} else {
							this.$router.push({
								name: 'home'
							})
						}
					})
					.catch(() => {
						// this.$message({
						// 	type: 'info',
						// 	message: '已取消删除'
						// });
					})
			},

			//跳转项目服务模块页面
			goserve() {
				if (this.user_info != '') {
					this.$router.push({
						name: 'PartnerManagement',
					})
				} else {
					this.$router.push({
						name: 'LoginIdex',
					})
				}
			},

			//跳转关于我们
			goabout() {
				this.$router.push({
					name: 'about',
				})
			},

			//跳转个人中心
			gomeyself() {
				localStorage.setItem('left_once_index', 9)
				localStorage.setItem('twice_index', 91)
				this.$router.push({
					name: 'Mokecertificationsystem',
				})
			},

			tolishi() {
				console.log(this.lsstatus);
				this.lsstatus = !this.lsstatus
			},

			tonicheng(index) {

				this.ncstatus = index
			},

			totuichu() {
				this.status = 4
				this.dlstatus = true
			},

			togeren() {
				console.log('@@');
				this.status = 2
				// this.ncstatus = 1
				// this.$router.push({
				// 	name: 'ziliao',
				// })
			}




		},
	}
</script>

<style scoped>
	.del-model {
		background-color: #e53b3c;
	}

	body {
		margin: 0 !important;
		background-color: #fff;
	}

	.header_search_border {
		width: 668px;
	}

	.nologin {
		background-color: #e53b3c;
		color: #ffffff !important;
		height: 32px;
		line-height: 32px;
		/* margin-top: 16px; */
		font-size: 14px;
		width: 112px;
		text-align: center;
		border-radius: 4px;
	}

	.el-container {
		margin: 0 !important;
		cursor: pointer;
	}

	.el-header {
		padding: 0;
		width: 100%;
		height: 64px;
		background: rgba(248, 248, 248, 0.4);
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 64px;
		color: #333333;
	}

	.logo {
		width: 85px;
		height: 45px;
		display: block;
		float: left;
		margin-top: 15px;

		/* margin-left: 90px; */
		/* object-fit: cover; */
	}

	.address_text {
		width: auto;
		height: 100%;
		line-height: 74px;
		margin-left: 15px;
		float: left;
		font-size: 14px;
	}

	.address_text_1 {
		width: auto;
		height: 100%;
		float: left;
		margin-left: 173px;
		/* font-size: 16px; */
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 22px;
		color: #666666;
	}

	.address_logo {
		width: 20px;
		height: 20px;
		margin-top: 17px;
		margin-right: 10px;
		position: relative;
		top: 6px;
	}

	.address_logo_2 {
		width: 12px;
		height: 8px;
		margin-left: 10px;
	}

	.header_right {
		width: auto;
		height: 100%;
		float: right;
		margin-right: 120px;
	}

	.header_right_message {
		width: 25px;
		height: 20px;
		position: relative;
		float: left;
	}

	.header_right_message img {
		width: 20px;
		height: 20px;
	}

	.header_right_message_acter {
		width: 12px;
		height: 12px;
		background: #e53b3c;
		border: 1px solid #ffffff;
		border-radius: 50%;
		position: absolute;
		right: 0;
		top: 10px;
	}

	.header_right_ul {
		width: auto;
		height: 100%;
		float: left;
	}

	.header_right_ul li {
		/* font-size: 16px; */
		font-family: PingFangSC-Regular;
		color: #666666;
		float: left;
		margin-left: 40px;
		list-style: none;
		/* font-size: 16px; */
		font-family: PingFang SC;
		font-weight: 400;
	}

	.header_right_ul li img {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		position: relative;
		top: 8px;
		margin-right: 10px;
	}

	.header_button {
		font-size: 10px;
		margin-left: 12px;

	}

	.header_buttons {
		font-size: 10px;
		color: #E53B3C;
		margin-left: 12px;
	}

	.header_yy {
		/* width: 100px; */
		height: 30px;
		margin-left: 6px;
		font-size: 10px;
	}

	.header_yys {
		/* width: 100px; */
		height: 30px;
		margin-left: 6px;
		color: #E53B3C;
		font-size: 10px;
	}

	.header_search {
		width: 742px;
		height: 42px;
		border-radius: 50px;
		border: 2px solid #FF6C34;
		display: flex;
		align-items: center;
		margin-top: 50px;
	}

	.header_gouwu {
		/* width: 100px; */
		height: 40px;
		border-radius: 8px;
		border: 2px solid #FF6C34;
		display: flex;
		align-items: center;
		margin-right: 80px;
		position: relative;

	}

	.header_gouwu_img {
		width: 20px;
		height: 20px;
		margin-left: 12px;
	}

	.header_search_button {
		width: 74px;
		height: 40px;
		background-color: #FF6C34;
		border-radius: 15px;
		font-size: 14px;
		color: #ffffff;
		text-align: center;
		line-height: 36px;
	}

	input::placeholder {
		font-weight: 400;
		font-size: 14px;
		color: #BFBFBF;
	}

	.header_lishi {
		width: 742px;

		position: absolute;
		background-color: #ffffff;
		z-index: 999;
		box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.06);
		border-radius: 0px 0px 8px 8px;
	}

	.nczhankai {
		width: 115px;
		height: 98px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #EEEEEE;
	}

	.djnicheng {
		width: 115px;
		height: 98px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #EEEEEE;
		z-index: 99999;
	}

	::v-deep input:focus {
		outline: none;
	}

	.container-line {
		position: absolute;
		width: 32px;
		height: 6px;
		background: #e53b3c;
		left: 22%;
		bottom: 10%;
		border-radius: 8px;
	}

	.active {
		color: #e53b3c;
	}

	.container-item:not(:first-child) {
		margin-left: 10px;
	}

	.container-footer {
		width: 100%;
		text-align: center;
		position: fixed;
		bottom: 5%;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.container-one {
		width: 100%;
		height: auto;
		margin: 10px 0 20px 0;
	}

	.container-box-one {
		width: 96%;
		height: 48px;
		line-height: 48px;
		background: rgba(255, 255, 255, 0.7);
		border-radius: 4px;
		padding: 0 2%;
		border: 1px solid #EEEEEE;
		margin-top: 32px;
	}

	.container-icon {
		width: 24px;
		height: 24px;
		display: block;
		margin: 11px 10px 0 0px;
	}

	.m-t-xs {
		margin-top: 10px;
	}

	.container-code {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4e8cff;
	}

	.container-denglu {
		font-size: 13px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #646c78;
		position: relative;
		text-align: center;
		margin-top: 40px;
	}

	.container-denglu::before {
		position: absolute;
		content: "";
		width: 132px;
		height: 1px;
		background: #646c78;
		top: 8px;
		left: 0px;
	}

	.container-denglu::after {
		position: absolute;
		content: "";
		width: 132px;
		height: 1px;
		background: #646c78;
		top: 8px;
		right: 0px;
	}

	.container-sanfang {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		text-align: center;
		color: #13161d;
		margin-top: 30px;
	}

	.container-sanfang img {
		width: 28px;
		height: 28px;
		position: relative;
		top: 10px;
	}

	.backLogin {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		text-align: center;
		color: #333333;
	}

	.erweim {
		width: 260px;
		height: 260px;
		display: block;
		margin: 20px auto;
	}

	.forget {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4e8cff;
		/* text-align: center; */
		margin-top: 30px;
	}

	.container-desc {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #999999;
		margin-top: 10px;
	}

	::v-deep input:focus {
		outline: none;
	}

	.container-input {
		width: 70%;
		height: 48px;
		line-height: 48px;
		border: none;
		background: rgba(255, 255, 255, 0.1);
		/*color: #999999;*/
		font-size: 14px;
	}

	.container-btn {
		width: 100%;
		height: 48px;
		border-radius: 4px;
		background: rgba(178, 13, 1, 1);
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 48px;
		text-align: center;
		color: #ffffff;
		margin: 0 auto;
		margin-top: 24px;
	}

	.top {
		width: 100%;
		height: 10%;
		background-color: #ffffff;
	}

	.container-icon {
		width: 24px;
		height: 24px;
		display: block;
		margin: 11px 10px 0 0px;
	}

	.m-t-xs {
		margin-top: 10px;
	}

	.container-code {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4e8cff;
	}

	.container-denglu {
		font-size: 13px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #646c78;
		position: relative;
		text-align: center;
		margin-top: 40px;
	}

	.container-denglu::before {
		position: absolute;
		content: "";
		width: 132px;
		height: 1px;
		background: #646c78;
		top: 8px;
		left: 0px;
	}

	.container-denglu::after {
		position: absolute;
		content: "";
		width: 132px;
		height: 1px;
		background: #646c78;
		top: 8px;
		right: 0px;
	}

	.container-sanfang {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		text-align: center;
		color: #13161d;
		margin-top: 30px;
	}

	.container-sanfang img {
		width: 28px;
		height: 28px;
		position: relative;
		top: 10px;
	}

	.backLogin {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		text-align: center;
		color: #333333;
	}

	.erweim {
		width: 260px;
		height: 260px;
		display: block;
		margin: 20px auto;
	}

	.forget {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #4e8cff;
		/* text-align: center; */
		margin-top: 30px;
	}

	.container-desc {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #999999;
		margin-top: 10px;
	}

	::v-deep input:focus {
		outline: none;
	}

	.container-input {
		width: 70%;
		height: 48px;
		line-height: 48px;
		border: none;
		background: rgba(255, 255, 255, 0.1);
		/*color: #999999;*/
		font-size: 14px;
	}

	.container-btn {
		width: 100%;
		height: 48px;
		border-radius: 4px;
		background: rgba(178, 13, 1, 1);
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 48px;
		text-align: center;
		color: #ffffff;
		margin: 0 auto;
		margin-top: 24px;
	}

	.top {
		width: 100%;
		height: 10%;
		background-color: #ffffff;
	}
</style>
// zn.js
export default {
	publicheader: {
		发布: '发布',
		
		消防: '消防',
		
		更新: '更新',
		
		专利技术: '专利技术',
		
		提交成功: '提交成功',
		消防: '消防',
		
		收费: '收费',
		免费: '免费',
		
		请先登录: '请先登录',
		
		参会人员: '参会人员',
		请输入: '请输入',
		待支付: '待支付',
		
		
		请输入公司名称: '请输入公司名称',
		请输入社会信用代码: '请输入社会信用代码',
		请输入公司地址: '请输入公司地址',
		请输入邮箱地址: '请输入邮箱地址',
		请输入联系人: '请输入联系人',
		请输入联系方式: '请输入联系方式',
		请上传营业执照: '请上传营业执照',
		
		
		开通前请阅读: '开通前请阅读',
		会员协议: '会员协议',
		
		确定: '确定',
		去登录: '去登录',
		
		新密码旧密码不一致: '新密码旧密码不一致',
		
		格式支持: '格式支持PNG.JPG',
		消防产品: '消防产品',
		请输入文章标题: '请输入文章标题(2~30个字',
		共: '共',
		字: '字',
		发文规范: '发文规范',
		
		选择模板: '选择模板',
		模版: '模板',
		下载模板: '下载模板',
		提交: '提交',
		
		请上传凭证: '请上传凭证',
		
		
		
		
		
		
		保存资料: '保存资料',
		发布类型: '发布类型',
		关闭: '关闭',
		请选择: '请选择',
		
		暂无开始的活动: '暂无开始的活动',
		报名数: '报名数',
		
		确认退出登录: '确认退出登录',
		
		全部: '全部',
		手机号: '手机号',
		请输入你需要搜索的关键词: '请输入你需要搜索的关键词',
		北京田圆科技: '田圆科技消防技术交流网',
		消防技术交流网: '消防技术交流网',
		登录: '登录',
		注册: '注册',
		中文: '中文',
		英文: '英文',
		新闻资讯: '新闻资讯',
		科技动态: '科技动态',
		产品标准: '产品标准',
		新产品新技术: '新产品新技术',
		项目展示: '项目展示',
		需求发布: '需求发布',
		技术交流: '技术交流',
		关于我们: '关于我们',
		社会新闻: '社会新闻',
		热点时间: '热点时间',
		置顶: '置顶',
		浏览量: '浏览量',
		服务热线: '服务热线',
		电子邮箱: '电子邮箱',
		关于我们: '关于我们',
		常见问题: '常见问题',
		账号管理: '账号管理',
		会员开通: '会员开通',
		企业认证: '企业认证',
		京ICP备xxxxxxxx号: '京ICP备xxxxxxxx号',
		Copyright: 'Copyright © 2022-现在 田圆科技消防技术交流网',
		身份选择: '身份选择',
		个人: '个人',
		企业: '企业',
		注册: '注册',
		验证码: '验证码',
		重新获取: '重新获取',
		请输入密码: '请输入密码',
		请再次输入密码: '请再次输入密码',
		已阅读并同意: '已阅读并同意《用户注册协议》与《隐私政策》',
		登录: '登录',
		已有帐号: '已有帐号？去登录',
		企业信息: '企业信息',
		基本信息: '基本信息',
		公司名称: '公司名称',
		统一社会信用代码: '统一社会信用代码',
		公司地址: '公司地址',
		邮箱地址: '邮箱地址',
		联系信息: '联系信息',
		联系人: '联系人',
		联系方式: '联系方式',
		联系信息: '联系信息',

		营业执照: '营业执照',
		确认: '确认',
		账号登录: '账号登录 ',
		手机验证码登录: '手机验证码登录',
		你可以直接输入你的账号和密码登录: '你可以直接输入你的账号和密码登录',
		请输入手机号: '请输入手机号',
		请输入密码: '请输入密码',
		忘记密码: '忘记密码',
		还没帐号去注册: '还没帐号？去注册',
		找回密码: '找回密码',
		请输入手机号: '请输入手机号',
		请输入验证码: '请输入验证码',
		获取验证码: '获取验证码',
		请输入新密码: '请输入新密码',
		请确认新密码: '请确认新密码',
		验证码登录: '验证码登录',
		账号登录: '账号登录 ',
		开通会员享受更多权益查看更多及时资讯: '开通会员享受更多权益，查看更多及时资讯！',
		去开通: '去开通',
		暂不: '暂不',
		开通会员: '开通会员',
		开通立享权益: '开通立享权益',
		白金会员: '白金会员',
		钻石会员: '钻石会员',
		至尊会员: '至尊会员',
		会员权益: '会员权益',
		支付方式: '支付方式',
		在线支付: '在线支付',
		线下打款: '线下打款',
		支付方式扫码支付: '支付方式：扫码支付',
		支付宝: '支付宝',
		开通前请阅读会员协议: '开通前请阅读《会员协议》 ',
		请支付: '请支付',
		支付成功: '支付成功！',
		支付方式上传打款凭证: '支付方式：上传打款凭证',
		开通前请阅读会员协议: '开通前请阅读《会员协议》 《自动续费规则》',
		恭喜您: '恭喜您，后台已审核，会员开通成功！',
		我知道了: '我知道了',

		很遗憾: '很遗憾，后台审核不通过，会员暂未开通您可以点击查看原因！',
		查看原因: '查看原因',
		返回: '返回',
		查看更多资讯: '查看更多资讯→',
		浏览量: '浏览量',
		行业动态: '行业动态',
		查看更多标准: '查看更多标准→',
		即将实施: '即将实施',
		现行: '现行',
		发布于: '发布于',
		实施于: '实施于',
		产品技术信息: '产品技术信息',
		查看更多标准: '查看更多标准→',
		产品标题: '产品标题',
		持有单位: '持有单位',
		交易方式: '交易方式',
		联系方式: '联系方式',
		操作: '操作',
		查看详情: '查看详情',
		合作信息: '合作信息',
		查看更多信息: '查看更多信息→',
		展览会议: '展览会议',

		查看更多展览: '查看更多展览→',
		付费: '付费',
		未开始: '未开始',
		报名: '报名',
		进行中: '进行中',
		已结束: '已结束',
		报名: '报名',
		查看更多动态: '查看更多动态',
		查看更多标准: '查看更多标准',
		预览: '预览',
		下载: '下载',
		即将实施: '即将实施',
		现行: '现行',
		废止: '废止',
		查看更多标准: '查看更多标准',
		友情链接名称: '友情链接名称',
		一级: '一级',
		二级: '二级',
		三级: '三级',
		持有单位: '持有单位',
		交易方式: '交易方式',
		报名记录: '报名记录',
		报名: '报名',
		已报名展会: '已报名展会',
		类型: '类型',
		价格: '价格',
		状态: '状态',
		核销状态: '核销状态',
		未核销: '未核销',
		未开始: '未开始',
		已核销: '已核销',
		进行中: '进行中',

		展览会议报名: '展览会议报名',
		参会人名称: '参会人名称',
		请输入: '请输入',
		联系方式: '联系方式',
		支付金额: '支付金额',
		支付宝: '支付宝',
		微信: '微信',
		备注: '备注',
		请输入: '请输入',
		取消: '取消',
		确认: '确认',
		报名成功: '报名成功！',
		展览会议报名: '展览会议报名',
		参会人名称: '参会人名称',
		联系方式: '联系方式',
		已开通: '已开通',
		确认退出登录么: '确认退出登录么？',
		选择分类: '选择分类',
		发布文章: '发布文章',
		发文规范: '发文规范',
		请输入文章标题: '请输入文章标题(2~30个字）',
		还需输入2个字: '还需输入2个字',
		请输入正文: '请输入正文',
		添加图片: '添加图片',
		回到顶部: '回到顶部',
		确认发布: '确认发布',
		上传图片: '上传图片',
		本地上传: '本地上传',
		厂家名称: '厂家名称',
		联系人: '联系人',
		联系方式: '联系方式',
		电子邮箱: '电子邮箱',
		回到顶部: '回到顶部',
		确认发布: '确认发布',
		专利技术类: '专利技术类',
		持有单位: '持有单位',
		交易方式: '交易方式',
		确认发布: '确认发布',
		消息中心: '消息中心',
		系统通知: '系统通知',
		审核通知: '审核通知',
		报名通知: '报名通知',
		没有更多历史消息了: '没有更多历史消息了',
		返回: '返回',
		个人账号: '个人账号',
		白金会员: '白金会员',
		会员权益: '会员权益',
		个人中心: '个人中心',
		修改密码: '修改密码',
		退出: '退出',
		原密码: '原密码',
		修改密码: '修改密码',
		请输入原密码: '请输入原密码',
		确认密码: '确认密码',
		请输入原密码: '请输入原密码',
		保存: '保存',
		个人资料: '个人资料',
		个人信息: '个人信息',
		编辑资料: '编辑资料',
		头像: '头像',
		联系方式: '联系方式',
		昵称: '昵称',
		统一社会信用代码: '统一社会信用代码',
		营业执照: '营业执照',
		关于我们: '关于我们',
		常见问题: '常见问题',
		账号管理: '账号管理',
		会员开通: '会员开通',
		企业认证: '企业认证',
		确认退出登录么: '确认退出登录么？',
		
		标准检索: '标准检索',
		标准类型: '标准类型',
		标准性质: '标准性质',
		标准状态: '标准状态',
		请输入产品关键词: '请输入产品关键词',
		// 发布: '发布',
		// 发布: '发布',
		// 发布: '发布',
		// 发布: '发布',
		// 发布: '发布',
		
		





		message: "消息",
		shopping: "Hi~欢迎来到田圆科技消防技术交流网",
		Order: '我的订单',
		logo: '田圆科技消防技术交流网',
		qichepeijian: '汽车配件系列',
		zhiliang: '以质量求生存，以效益求发展',
		hydenglu: '欢迎登录',
		youxianghao: '请输入邮箱号',
		srmima: '请输入密码(6~12位字母+数字)',
		denglu: '登录',
		zhuce: '注册账号',
		wjmima: '忘记密码',
		tongyi: '我同意',
		zhengce: '《用户注册协议》与《隐私政策》',
		zcxy: '用户注册协议',
		yszc: '隐私政策',
		yu: '与',
		zixun: '咨询热线',
		jubaodh: '举报电话',
		kuaijie: '快捷链接',
		gywom: "关于我们",
		lxwm: '联系我们',
		yszc: '隐私政策',
		mztk: '免责条款',
		yonghuduan: '用户端APP',
		sryqm: '请输入邀请码（选填）',
		hqyzm: '获取验证码',
		qsrssnr: '请输入搜索内容',
		sousuo: '搜索',
		gouwuche: '购物车',
		qbsp: '全部商品',
		shouye: '首页',
		xpzx: '新品中心',
		jlzx: '领券中心',
		lxkf: '联系客服',
		huidingbu: '回顶部',
		cainixihuan: '猜您喜欢',
		myorder: '我的订单',
		ckgd: '查看更多',
		tishi: '提示',
		tcdenglu: '确认退出登录吗？',
		quere: '确认',
		quxiao: '取消',
		ssjieguo: '搜素结果',
		zonghe: '综合',
		xiaoliang: '销量',
		jiage: '价格',
		jgqujian: '价格区间',
		tjsp: '推荐商品',
		spxq: '商品详情',
		shoucang: '收藏',
		fenxiang: '分享',
		yhj: '优惠券',
		kuanshi: '款式',
		yanse: '颜色',
		shulaing: '数量',
		jian: '件',
		kucun: '库存',
		ljgoumai: '立即购买',
		jrgouwuche: '加入购物车',
		sppingjia: '商品评价',
		ljshiyong: '立即领取',
		qckeyong: '全场可用',
		youxiaoqi: '有效期至',
		haopinglv: '好评率：',
		qunabu: '全部',
		haopl: '好评',
		zhongping: '中评',
		chaping: '差评',
		youtu: '有图',
		guige: '规格',
		pjxingji: '评价星级',
		lxkefu: '联系客服',
		gzshijian: '工作时间',
		dianhua: '电话',
		youxiang: '邮箱',
		boda: '拨打',
		fuzhi: '复制',
		spxinxi: '商品信息',
		danjia: '单价',
		shulaing: '数量',
		jine: '金额',
		caozuo: '操作',
		quanxuan: '全选',
		shanxhu: '删除',
		yirushoucang: '移入收藏夹',
		yxshagnp: '已选商品',
		jian: '件',
		heji: '合计：',
		jiesuan: '去结算',
		cnxh: '猜您喜欢',
		gouwuche: '购物车',
		jiesuan: '结算',
		shouhuodizhi: '收货地址',
		mydizhi: '您还没有收货地址，你可以去',
		xzdizhi: '新增收货地址',
		qrxinxi: '确认订单信息',
		sptp: '商品图片',
		spxinxi: '商品信息',
		danjia: '单价',
		shuliang: '数量',
		xiaoji: '小计',
		yhj: '优 惠 券',
		xzyhj: '选择优惠券',
		yf: '运 费',
		hdfk: '货到付款',
		wuliu: '物流',
		liuyan: '留 言',
		jyliuyan: '建议留言前与先沟通确认',
		spje: '件商品，商品金额',
		yfje: '应付金额',
		tjdingdan: '提交订单',
		xzdz: '新增收货地址',
		gs: '公司',
		mrdz: '默认地址',
		swmr: '设置为默认',
		binaji: '编辑',
		delete: '删除',
		sqdz: '收起地址',
		qrdd: '确认订单信息',
		sptp: '商品图片',
		spxx: '商品信息',
		xtnr: '选填：填写内容已和卖家协商确认',
		tjdd: '提交订单',
		xinzengdizhi: '新增地址',
		bq: '标签',
		qsrbq: '请输入地址标签',
		home: '家',
		xuexiao: '学校',
		shr: '收货人',
		qsrshr: '请输入收货人',
		sir: '先生',
		sister: '女士',
		lxdh: '联系电话',
		szdq: '所在地区',
		xxdz: '详细地址',
		sedz: '设为默认地址',
		quxiao: '取消',
		bcdz: '保存地址',
		yfsm: '运费说明',
		qrdd: '确认订单',
		tjdd: '提交订单',
		cjdd: '创建订单成功，请尽快付款！ 订单编号',
		zfsj: '请在30分钟内支付，否则订单自动取消',
		xzzf: '选择支付方式',
		ljzf: '立即支付',
		yfje: '应付金额',
		ddcg: '订单支付成功!',
		zfje: '支付金额',
		zffs: '支付方式',
		zfsj: '支付时间',
		ckdd: '查看订单',
		fhsy: '返回首页',
		grxx: '个人信息',
		tx: '头像',
		yqm: '邀请码',
		nc: '昵称',
		xb: '性别',
		nan: '男',
		nv: '女',
		shengri: '生日',
		baocun: '保存',
		ddzx: '订单中心',
		psdd: '配送订单',
		wdqb: '我的钱包',
		qbye: '钱包余额',
		yhj: '优惠券',
		qtgn: '其他功能',
		wdshoucang: '我的收藏',
		wdzuji: '我的足迹',
		wdxx: '我的消息',
		yqhy: '邀请好友',
		tdtg: '团队推广',
		dzgl: '地址管理',
		shezhi: '设置',
		xgmima: '修改密码',
		xgyx: '修改邮箱号',
		bzfk: '帮助与反馈',
		fywm: '关于我们',
		psdd: '配送订单',
		qb: '全部',
		dfk: '待付款',
		dfh: '待发货',
		dsh: '待收货',
		dpj: '待评价',
		tk: '退款/售后',
		shouhou: '售后',
		qbrq: '全部日期',
		spxx: '商品信息',
		shuliang: '数量',
		spcz: '商品操作',
		je: '金额',
		zhuangtai: '状态',
		jycz: '交易操作',
		ddbh: '订单编号',
		sqsh: '申请售后',
		shifu: '实付',
		dfh: '待发货',
		ddxq: '订单详情',
		txfh: '提醒发货',
		qfk: '去付款',
		qrsh: '确认收货',
		ckwl: '查看物流',
		pingjia: '评价',
		tuihuo: '退货',
		djihui: '待寄回',
		cxsq: '撤销申请',
		jhxx: '寄回信息',
		tk: '退款',
		scdd: '删除订单',
		grzx: '个人中心',
		gaidd: '该订单会为您保留30分钟（从下单之日算起），30分钟之后如果还未付款，系统将自动取消该订单。',
		tjdd: '提交订单',
		fkcg: '付款成功',
		ddfh: '等待发货',
		ddsh: '等待收货',
		shrxx: '收货人信息',
		shr: '收货人',
		fkxx: '付款信息',
		fkfs: '付款方式',
		lxdh: '联系电话',
		shdz: '收货地址',
		ddxx: '订单信息',
		ddbz: '订单备注',
		ddbh: '订单编号',
		fz: '复制',
		xdsj: '下单时间',
		spje: '商品金额',
		yfk: '应付款',
		xzqxyy: '请选择取消原因',
		qdqx: '确定取消',
		zbqx: '暂不取消',
		zfsj: '支付时间',
		sqsh: '申请售后',
		shxz: '售后选择',
		tkyy: '退款原因',
		tkje: '退款金额',
		tksm: '退款说明',
		qxz: '请选择',
		scpz: '上传凭证',
		tj: '提交',
		zczh: '注册账号',
		qsryx: '请输入邮箱号',
		qsryz: '请输入验证码',
		hqyzm: '获取验证码',
		qsrmm: '请输入密码(6~12位字母+数字)',
		qsryqm: '请输入邀请码（选填）',
		yyzh: '已有账号？去',
		xdsj: '下单时间',
		zfsj: '支付时间',
		wldh: '物流单号',
		wlmc: '物流名称',
		wz: '网址',
		dddpj: '订单待评价，您的评价会给其他买家参考～',
		cpms: '产品描述',
		pjms: '评价描述',
		qsrpj: '请输入您的评价',
		sctp: '上传图片',
		nmpk: '匿名评价',
		ddywc: '订单已完成，祝您生活愉快～',
		cxsq: '撤销申请',
		xgsq: '修改申请',
		qddptcl: '请等待平台处理',
		nycgfq: '您已成功发起退款申请，请耐心等待平台处理。',
		cxsq: '撤销申请',
		xgsq: '修改申请',
		xtts: '系统提示',
		cxsq: '撤销申请',
		djh: '待寄回',
		qjhsp: '请寄回商品',
		ptyty: '平台已同意退货申请，请寄回商品并提交物流信息。',
		jhdz: '寄回信息',
		thdz: '退货地址',
		tksm: '退款说明',
		tkxx: '退款信息',
		dqzt: '当前状态',
		fwlx: '服务类型',
		tkyy: '退款原因',
		tksm: '退款说明',
		tkpz: '退款凭证',
		txjhxx: '填写寄回信息',
		thty: '为了给您更优的退换货时效和体验，我公司建议优先选择协作快递顺峰',
		wldh: '物流单号',
		lxdh: '联系电话',
		thsm: '退换说明',
		tkpz: '退款凭证',
		wldh: '物流单号',
		wjmm: '忘记密码',
		qsryxh: '请输入邮箱号',
		shywc: '售后已完成',
		ptytk: '平台已退款',
		tkje: '退款金额',
		tkzh: '退款账户',
		tksj: '退款时间',
		tksm: '退款说明',
		tkpz: '退款凭证',
		wldh: '物流单号',
		scdd: '删除订单',
		th: '退货',
		shgb: '售后关闭',
		cxsj: '撤销时间',
		nycs: '您已撤销本次退款申请，如有问题未解决，您可重新发起申请',
		nycgtk: '您已成功发起退款申请，请耐心等待平台处理。',
		wdqb: '我的钱包',
		yuer: '余额(元)',
		djs: '待结算(元)',
		yemx: '余额明细',
		qunabu: '全部',
		srmx: '收入记录',
		zcjl: '支出记录',
		djsmx: '待结算明细',
		djslb: '待结算列表',
		djs: '待结算',
		syje: '收益金额：',
		ddbh: '订单编号：',
		ddje: '订单金额：',
		ztxj: '直推下级蔡小菜购买汽车轮胎，预计获得返佣10元',
		jssj: '结算时间',
		jslx: '结算类型',
		bzsm: '备注说明',
		tixian: '提现',
		txje: '提现金额',
		zhye: '账户余额',
		quanbu: '全部',
		txsq: '提现收取 1% 的手续费',
		txzh: '提现账号',
		qsryhk: '请输入提现的银行卡号',
		sqtx: '申请提现',
		yemx: '余额明细',
		nr: '内容',
		jiner: '金额',
		zhuangtai: '状态',
		shijian: '时间',
		shenhezhong: '审核中',
		shenhebohui: '审核驳回',
		yidaozhang: '已到账',
		txgz: '提现规则',
		tjcg: '提交申请成功',
		txzh: '提现账号',
		txje: '提现金额',
		hydl: '欢迎登录',
		zczh: '注册账号',
		wjmm: '忘记密码',
		wsy: '未使用',
		ygq: '已过期',
		qx: '全选',
		qxsc: '取消收藏',
		xl: '销量',
		wdzj: '我的足迹',
		shanchu: '删除',
		ddxx: '订单消息',
		ppgg: '平台公告',
		ddyfh: '订单已发货',
		xtsj: '系统升级提醒',
		ggxq: '公告详情',
		yqhy: '邀请好友',
		yqm: '邀请码',
		fuzhi: '复制',
		tdtg: '团队推广',
		ztgrs: '总推广人数',
		bydd: '本月订单',
		ljdd: '累计订单',
		jrsy: '今日收益',
		bysy: '本月收益',
		ljsy: '累计收益',
		qbry: '全部人员',
		ztry: '直推人员',
		jtry: '间推人员',
		gzsm: '规则说明',
		zhitui: '直推',
		dan: '单',
		ren: '人',
		yuan: '元',
		zcsj: '注册时间',
		fxgz: '分销规则',
		tgdd: '推广订单',
		yjsy: '预计收益',
		sydj: '收益等级',
		xdsj: '下单时间',
		ddsf: '订单实付金额',
		zcxy: '注册协议',
		symx: '收益明细',
		kssj: '开始时间',
		jssj: '结束时间',
		dzgl: '地址管理',
		xzdz: '新增地址',
		swmr: '设为默认地址',
		xiugai: '修改',
		shanchu: '删除',
		biaoqian: '标签',
		qingshuru: '请输入地址标签',
		shr: '收货人',
		qsrshr: '请输入收货人姓名',
		qsrlxdh: '请输入联系电话',
		bjdz: '编辑地址',
		qdsc: '确定删除该地址吗？',
		xgsf: '修改方式',
		jmmyz: '旧密码验证修改',
		yxhyzxg: '邮箱号验证修改',
		jiumima: '旧密码',
		qsrjmm: '请输入旧密码',
		xmm: '新密码',
		qsrmm: '请输入新密码',
		qrxmm: '确认新密码',
		zcsrxmm: '请再次输入新密码',
		yxyz: '我们要对您绑定的邮箱号进行验证',
		yzm: '验证码',
		qsryzm: '请输入验证码',
		hqyzm: '获取验证码',
		xmm: '新密码',
		qsrxmm: '请输入新密码',
		qrxmm: '确认新密码',
		qzcsr: '请再次输入新密码',
		zhzc: '账号注册',
		mmcz: '密码重置',
		mmxg: '密码修改',
		yjfk: '意见反馈',
		dlsb: '登录失败',
		zhzx: '账号注销',
		qtwt: '其他问题',
		bzfk: '帮助与反馈',
		bzxq: '帮助详情',
		tjsh: '提交了的售后服务，什么时候审核完？',
		tjyjfk: '如您使用过程中，存在其他任何疑问，可直接点击下方【意见反馈】进行提交哦~',
		bzfk: '帮助与反馈',
		fklx: '反馈类型',
		cpjy: '产品建议',
		gngz: '功能故障',
		qtwt: '其他问题',
		fkwt: '反馈记录',
		wtms: '问题描述',
		wtxxms: '问题描述的越详细，有助于我们更快的解决问题',
		tjfk: '提交反馈',
		fksj: '反馈时间',
		pthf: '平台回复',
		gywm: '关于我们',
		cnxh: '猜您喜欢',



		yfk: '已收款',
		ywc: '已完成',
		ksrq: '开始日期',
		jsrq: '结束日期',
		shuoming: '说明',
		zhi: '至',
		jjsy: '间接收益',
		shwc: '售后完成',
		tcdl: '退出登录',
		qdtc: '确认要退出登录吗?',
		qd: '确定',
		qx: '取消',
		linjuan: '领劵',
		xzcm: '选择尺码',
		ljsy: '立即使用',
		man: '满',
		kejian: '可减',
		ddh: '订单号',
		jjcg: '交易成功',
		ddyqx: '订单已取消',
		sy: '剩余',
		fk: '付款',
		qxdd: '取消订单',
		ytx: '已提醒',
		ckph: '仓库正在备货，请耐心等待',
		zzps: '您购买的商品正在配送中，请耐心等待。',
		ddpjck: '订单待评价，您的评价会给其他买家参考。',
		znyk: '订单已完成，祝您生活愉快。',
		yfk: '已付款',
		yfh: '已发货',
		ywc: '已完成',
		ypj: '已评价',
		shz: '售后中',
		shwc: '售后完成',
		yqx: '已取消',
		yf: '运费',
		sfk: '实付款',
		wlgs: '物流公司',
		qsrwlgs: '请输入物流公司',
		qtxwldh: '请填写物流单号',
		qsrxt: '请输入(选填)',
		cxsqcg: '撤销申请成功',
		dsh: '待审核',
		djh: '待寄回',
		dtk: '待退款',
		shywc: '售后已完成',
		shgb: '售后关闭',
		shbh: '审核已驳回',
		qddtk: '请等待平台退款',
		ptjjtk: '平台已寄回，请等待平台收货并退款',
		ptcxcl: '平台同意后，请按照给出的退货地址退货，并请填写退货运单号进行提交。如平台拒绝，您可以修改申请后再次发起，平台会重新处理。',
		ptxsyz: '未于平台协商一致，请勿使用到到付或平邮，以免平台拒收。请填写真实的退货物流信息，逾期未填写，退货申请将自动关闭。',
		ptxz: '如果平台收到货并验货无误，将操作退款给您。钱款原路返回至您支付时选择的账户。',
		bccx: '您已撤销本次退款申请，如有问题未解决，您可重新发起申请。',
		tjsq: '提交申请',
		ptsh: '平台审核',
		jhsp: '寄回货品',
		ytk: '已退款',
		jhdz: '寄回地址',
		qbzwz: '请保证货物完整没有问题，我们收到货物之后会立即给您退款',
		zwjt: '中文',
		yw: '英文',
		ynw: '越南文',
		tw: '泰文',
		ylq: '已领取',
		xyx: '新邮箱',
		qsrxxx: '请输入新邮箱',
		yijs: '已结算',
		yjhdfy: '预计获得返佣',
		wjs: '未计算',

		qscyyt: '请上传应用截图，最多上传6张',
		cxsq: '重新申请',
		fbsj: '发布时间',
		qbsc: '全部删除',
		qsrzyx: '请输入正确邮箱号',
		qgxyhxy: '请勾选用户协议',
		qsrzqyyh: '请输入正确的账号和密码'




	},
}
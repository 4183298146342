<template>
	<div class="home">
		<publicheader></publicheader>
		<zoumadeng :message="1"></zoumadeng>
		<!-- <div style="width: 1200px;  margin: 0 auto;margin-left: 460px;">
			<el-pagination :current-page="currentPage" style="margin-top: 40px;margin-left: 260px;margin-bottom: 40px;"
				 background layout="prev, pager, next" @size-change="handleSizeChange" background
				@current-change="handleCurrentChange" :page-size="[12]" :total="totalength">
			</el-pagination>
		</div> -->
		<headertwo style="width: 1200px;margin: 0 auto;"></headertwo>
		<div style="width: 1200px; margin: 0 auto;background-color: #fff;margin-top: 16px;">
			<div style="height: 250px;">
				<el-carousel height="250px" style="margin: 0 auto;margin-left: 20px;height:250px;">
					<el-carousel-item style="height:250px;width: 1160px;" v-for="(item,index) in xplist" :key="index">
						<img style="height:250px;width: 1160px;" :src="item.thumb" alt="">
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div style="margin-top: 20px;margin-left: 40px;display: flex;width: 1200px;margin: 0 auto;padding-top: 22px;">
			<div v-for="(item,index) in qhList" :class="item.is_true?'shehui':'xinwen'" style=""
				@click="toqiehuan(item,qhList,index)">
				{{item.name}}
			</div>
		</div>
		<div v-for="(item,index) in xwlist" @click="todetail(item)" v-if="xwlist.length>0">
			<div style="width: 1200px;display: flex;justify-content: space-between;
					height: 213px;
					background: #FFFFFF;
					border-radius: 12px 12px 12px 12px;margin: 0 auto;margin-top: 12px;" >
				<img :src="item.img_url[0]" style="width: 267px;height: 173px;margin: 20px;" alt="" />
				<div
					style="width: 865px;height: 213px;background-color: #fff;display: flex;justify-content: space-between;flex-direction: column;">
					<div class="ceshi"
						style="display: flex;align-items: center;background-color: #fff;color: rgba(51, 51, 51, 1);margin-top: 20px;">
						<p style="background-color: #fff;">{{item.title}}</p>
						<div v-if="item.is_top==1" style="width: 40px;
					height: 20px;
					background: #0280FF;color: #fff;
					border-radius: 19px 19px 19px 19px;text-align: center;line-height: 20px;font-size: 12px;margin-left: 4px;">
							{{$t('publicheader.置顶')}}</div>
					</div>
					<div class=""
						style="display: flex;align-items: center;background-color: #fff;color: rgba(51, 51, 51, 1);margin-bottom: 40px;">
						<p style="background-color: #fff;">{{item.synopsis}}</p>
					</div>
					<div
						style="background-color: #fff;margin-bottom: 20px;display: flex;justify-content: space-between;">
						<div style="background-color: #fff;">
							<span
								style="background-color: #fff;font-size: 14px;color: rgba(51, 51, 51, 1);">{{item.nickname}}</span>
							<span
								style="background-color: #fff;font-size: 14px;color: rgba(170, 170, 170, 1);margin-left: 6px;">{{item.create_time}}</span>
						</div>
						<p
							style="color: rgba(255, 178, 2, 1);font-size: 14px;background-color: #fff;margin-right: 6px;">
							{{$t('publicheader.浏览量')}}：{{item.click_count}}</p>

					</div>

				</div>
			</div>

			<!-- <div style="width: 1200px; margin: 0 auto;margin-top: 20px;" v-else>

				<div style="width: 1200px;
			height: 283px;
			background: #FFFFFF;
			border-radius: 12px 12px 12px 12px;">

					<div id="" style="background: #FFFFFF;margin: 20px;padding-top: 20px;">

						{{item.title}}

					</div>

					<div style="display: flex;background: #FFFFFF;">

						<img :src="item.img_url[0]" style="width: 267px;height: 173px;margin-left: 20px;" alt="" />

					</div>

					<div
						style="background-color: #fff;margin-bottom: 20px;display: flex;justify-content: space-between;margin-left: 20px;margin-top: 10px;">

						<div style="background-color: #fff;">

							<span
								style="background-color: #fff;font-size: 14px;color: rgba(51, 51, 51, 1);">{{item.nickname}}</span>
							<span
								style="background-color: #fff;font-size: 14px;color: rgba(170, 170, 170, 1);margin-left: 4px;">{{item.create_time}}</span>

						</div>
						<p
							style="color: rgba(255, 178, 2, 1);font-size: 14px;background-color: #fff;margin-right: 6px;">
							{{$t('publicheader.浏览量')}}：{{item.click_count}}</p>
					</div>
				</div>
			</div> -->

		</div>
		
		<div style="width: 500px; margin: 0 auto;" v-if="xwlist.length==0">
			
			<img src="../image/zwsj.png" alt="" />
			
		</div>
		


		<div>
		</div>

		<publicfooter></publicfooter>
	</div>
</template>
<script>
	// import axios from 'axios';
	import HelloWorld from '@/components/HelloWorld.vue'
	import publicheader from '@/components/publicheader.vue' //主头部
	import headertwo from '@/components/headertwo.vue'
	import publicfooter from '@/components/publicfooter.vue' //主底部
	import zoumadeng from '@/components/zoumadeng.vue' //轮播图
	export default {
		name: 'HomeView',
		components: {
			HelloWorld,
			publicheader,
			headertwo,
			publicfooter,
			zoumadeng
		},
		data() {
			return {

				token: '',
				lunbolist: [],
				xplist: [],
				xhlist: [],
				page_size: 12, //列表的页数
				currentPage: 1,
				totalength: '',
				xwlist: [],
				shstatus: '',
				qhList: [],
				qiehuanid: 20
			}
		},
		created() {
			this.indexdata();
			console.log(JSON.parse(localStorage.getItem('userinfo')), '******');
			this.token = JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo'))
				.user_token : ''
			console.log(this.token, "----------");
			this.getxinwen()
			this.getqiehuan()
		},

		watch: {
			'$route'(to, from) {
				// 路由发生变化页面刷新
				window.location.reload();
			}
		},
		mounted() {
			if (localStorage.getItem('logtype', 1)) {
				localStorage.removeItem("logtype");
				// this.$forceUpdate()
				window.location.reload();
			}
		},
		methods: {

			todetail(item) {

				this.$router.push({
					name: 'zixunxiangqing',
					query: {
						detail_id: item.aid
					}
				})
			},

			toqiehuan(item, list, index) {

				this.qiehuanid = item.aid
				list.forEach((v, k) => {
					console.log(k, '@@@');
					if (k != item.aid) {
						v.is_true = false;
					}
				});
				item.is_true = !item.is_true;
				this.getxinwen()

			},


			getqiehuan() {

				this.$http({
					url: '/m7019/6699d19fc17f0',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						pid: 1

					}),
				}).then((res) => {
					if (res.data.code == 1) {

						this.qhList = res.data.data

					}
				})

			},

			// 获取新闻
			getxinwen() {
				console.log('@@@');
				// 获取猜你喜欢
				this.$http({
					url: '/m7019/6699d5544c65c',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						operationarticlecate_id: this.qiehuanid

					}),
				}).then((res) => {
					if (res.data.code == 1) {

						this.xwlist = res.data.data.data

					}
				})


			},

			// 跳转详情
			// todetail(id) {
			// 	console.log('@@@@');
			// 	this.$router.push({
			// 		name: 'detail',
			// 		query: {
			// 			detail_id: id
			// 		}
			// 	})
			// },

			// 跳转到新品
			toxinpin() {
				this.$router.push({
					name: 'MokefinancialIndex',

				})
			},

			//跳转咨询
			gozixun() {
				this.$router.push({
					name: 'WarehouseLogistics',
					query: {
						type: 1
					}
				})
			},

			//申请合作
			shenqinghezuo() {
				this.$router.push({
					name: 'WarehouseLogistics',
					query: {
						type: 2
					}
				})
			},

			//跳转计算器
			gojisuanqi() {
				this.$router.push({
					name: 'counterHome',
				})
			},

			//首页数据接口
			indexdata() {
				//获取首页轮播图
				this.$http({
					url: '/m7019/641e624160dd0',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						type: 3
					}),
				}).then((res) => {
					if (res.data.code == 1) {

						this.xplist = res.data.data

					}
				})



			},

			handleCurrentChange(val) {
				this.currentPage = val;
				this.indexdata();
				console.log(`当前页: ${val}`)
			},
			//左侧点击事件
			jrleftclick(ywmsaid, chanpinbiaoti, chanpintedian) {
				this.mkjrchanpinbiaoti = chanpinbiaoti
				this.mkjrchanpintedian = chanpintedian
				this.jrleftywms = ywmsaid
				this.getjrlist()
			},
			//金融模块获取右边数据列表
			getjrlist() {
				this.$http({
					url: '/prod-api/chanpinliebiao/getFirstProductList',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						yewumoshi: this.jrleftywms,
					}),
				}).then((res) => {
					if (res.data.code == 1) {
						this.mkjrSj = res.data.data.slice(0, 3)
					}
				})
			},

			//墨客金融移入移出事件
			jrsbenter(index) {
				this.jrmouserindex = index;
			},

			jrsbleave(index) {
				this.jrmouserindex = 9;
			},

			//物流服务点击事件
			wuliuclick(type) {
				this.wuliutype = type
			},

			//仓储点击事件
			cangchuclick(type) {
				this.cangchutype = type
			},

			//跳转热点详情
			gohotdata() {
				this.$router.push({
					name: 'hotData'
				})
			},

			//跳转更多金融产品
			gomorejrcp() {
				localStorage.setItem('headerIndex', 1)
				this.$router.push({
					name: 'prodectList'
				})
			},

			//跳转墨客金融主页
			gomorejrcpindex() {
				localStorage.setItem('headerIndex', 1)
				this.$router.push('/MokefinancialIndex');
			},

			//跳转金融产品详情
			gocpDetail(detail) {
				this.$router.push({
					name: 'prodectdetail',
					query: {
						aid: detail.aid
					}
				})
			},

			//跳转仓储物流
			gocangchuwuliu() {
				localStorage.setItem('headerIndex', 2)
				this.$router.push({
					name: 'HomeStoreList'
				})
			},


		},
	}
</script>
<style scoped>
	* {
		margin: 0;
		padding: 0;
		background-color: rgba(246, 246, 246, 1);
	}

	.shehui {

		width: 88px;
		height: 37px;
		background: #B20D01;
		color: #fff;
		border-radius: 19px 19px 19px 19px;
		text-align: center;
		line-height: 37px;
		margin-left: 20px;
	}

	.xinwen {

		width: 88px;
		height: 37px;
		border-radius: 19px 19px 19px 19px;
		color: #B20D01;
		margin-left: 20px;
		border: 1px solid #B20D01;
		text-align: center;
		line-height: 37px;
	}

	.ceshi {
		background-color: #fff !important;

	}



	.el-carousel {
		width: 100%;
		height: 480px;
		line-height: 480px;
	}

	.el-carousel__container {
		width: 100%;
		height: 480px;
		line-height: 480px;
	}

	.el-carousel img {
		width: 100%;
		height: 100%;
	}

	/* 谷歌 */
	::-webkit-scrollbar {
		display: none;
	}

	/* 火狐 */
	.one_swiper {
		overflow: -moz-scrollbars-none;
	}

	.el-carousel-item {
		width: 100%;
		height: 480px;
		margin: 0;
		line-height: 480px;
	}

	.el-carousel-item .el-image {
		width: 100%;
		height: 100%;
		display: block;
	}

	.el-carousel__arrow--left {
		width: 100px;
		height: 100px;
		background-color: darkorange !important;
		border: 2px solid salmon;
	}

	.two-one {
		width: 1200px;
		height: 60px;
		background: rgba(255, 249, 248, 0.4);
		box-shadow: 0px 2px 8px rgba(255, 226, 221, 0.42);
		border-radius: 7px;
		margin: 20px auto;
		overflow: hidden;
	}

	.two-one img {
		width: 20px;
		height: 20px;
		margin-top: 20px;
		margin-left: 20px;
		float: left;
	}

	.two-one-text {
		width: 95%;
		height: 100%;
		float: left;
		margin-left: 20px;
		line-height: 60px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
	}

	.three {
		width: 100%;
		/* height: 1319px; */
		background: rgba(255, 255, 255, 0.4);
	}

	.three_header {
		width: 400px;

		margin-top: 64px;
		display: flex;
		align-items: center;
		text-align: center;
		margin: 0 auto;
		margin-top: 70px;


	}

	.three_header_top {
		font-size: 28px;
		font-family: PingFang SC;
		font-weight: bold;
		line-height: 48px;
		color: #333333;
		display: block;
		position: relative;
		z-index: 999;
		margin: 11px;
	}

	.three_header_bottom {
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 33px;
		color: #999;
		position: relative;
		top: -22px;
		opacity: 0.3;
	}

	.three_one {
		width: 1200px;
		overflow: hidden;
		margin: 40px auto;
	}

	.three_one_list {
		width: 588px;
		height: 232px;
		background: #fff;
		box-shadow: 0px 4px 6px rgba(70, 59, 59, 0.08);
		opacity: 1;
		border-radius: 8px;
		float: left;
		margin-left: 24px;
	}

	.three_one_list_l {
		width: 385px;
		height: 100%;
		float: left;
	}

	.three_one_list_l p {
		padding-left: 40px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 28px;
		color: #333333;
		margin-top: 30px;
	}

	.three_one_list_l_text {
		font-size: 14px !important;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 22px;
		color: #999999 !important;
		margin-top: 16px !important;
	}

	.three_one_list_l_bq {
		width: 548px;
		overflow: hidden;
		margin-left: 40px;
	}

	.three_one_list_l_bq span {
		width: auto;
		height: 26px;
		border: 1px solid #bfbfbf;
		opacity: 1;
		border-radius: 4px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 26px;
		color: #999999;
		margin-right: 10px;
	}

	.three_one_list_l_bottom {
		width: 548px;
		overflow: hidden;
		margin-left: 40px;
		margin-top: 24px;
	}

	.three_one_list_l_bottom span {
		width: auto;
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 25px;
		color: #658bd1;
		margin-right: 10px;
	}

	.three_one_list_r {
		width: 203px;
		height: 232px;
		float: right;
	}

	.three_one_list:nth-child(2n + 1) {
		margin-left: 0;
	}

	.three_two {
		width: 1200px;
		height: auto;
		margin: 40px auto;
	}

	.three_two_content {
		width: 1200px;
		height: 676px;
		background-color: #fff;
		box-shadow: 0px 4px 6px rgba(70, 59, 59, 0.08);
		opacity: 1;
		border-radius: 8px;
	}

	.three_two_content_l {
		width: 532px;
		height: 676px;
		background: rgba(247, 248, 248, 0.39);
		border-radius: 8px 0px 0px 8px;
		position: relative;
		top: -18px;
		left: -23px;
		float: left;
	}

	.three_two_content_l img {
		width: 100%;
		height: 315px;
	}

	.three_two_content_l_text {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.three_two_content_l_text_name {
		width: 448px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 22px;
		color: #e53b3c;
		display: block;
		margin-left: 42px;
		margin-top: 32px;
	}

	.three_two_content_l_text_ms {
		width: 448px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 24px;
		color: #333333;
		display: block;
		margin-left: 42px;
		margin-top: 32px;
	}

	.three_two_content_bottom {
		width: 100%;
		height: 361px;
		position: absolute;
		left: 0;
		top: 315px;
	}

	.three_two_content_bottom_list {
		width: 448px;
		overflow: hidden;
		margin: 24px auto 45px auto;
	}

	.three_two_content_bottom_list_l {
		width: auto;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 22px;
		color: #333333;
	}

	.three_two_content_bottom_list_l img {
		width: 16px;
		height: 16px;
		float: right;
	}

	.three_two_content_bottom_list_l p {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 20px;
		color: #999999;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 16px;
	}

	.three_two_content_bottom_list_lp {
		line-height: 40px !important;
	}

	.three_two_content_r {
		width: 606px;
		height: 100%;
		float: left;
	}

	.three_two_content_r_list {
		width: 100%;
		overflow: hidden;
		border-bottom: 1px solid #efefef;
	}

	.three_two_content_r_list:last-child {
		border: none;
	}

	.three_two_content_r_list_l {
		width: 245px;
		height: 176px;
		margin-top: 24px;
		margin-bottom: 24px;
		float: left;
	}

	.three_two_content_r_list_l img {
		width: 100%;
		height: 100%;
	}

	.three_two_content_r_list_r {
		width: 345px;
		float: left;
		margin-left: 16px;
		margin-top: 24px;
	}

	.three_two_content_r_list_r_hot {
		width: 16px;
		height: 18px;
		margin-right: 10px;
	}

	.three_two_content_r_list_r_name {
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 28px;
		color: #333333;
	}

	.three_two_content_r_list_r_list {
		width: 100%;
		overflow: hidden;
		margin-top: 14px;
	}

	.three_two_content_r_list_r_list_l {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #999999;
		float: left;
	}

	.three_two_content_r_list_r_list_r {
		float: left;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #333333;
	}

	.three_two_content_r_list_r_list_r span {
		margin-left: 10px;
	}

	.three_two_content_r_list_r_list_r img {
		width: 14px;
		height: 14px;
	}

	.three_two_content_r_list_r_list_btn {
		width: 88px;
		height: 32px;
		border: 1px solid #e53b3c;
		border-radius: 4px;
		margin-top: 14px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 32px;
		text-align: center;
		color: #e53b3c;
	}

	.three_three {
		width: 220px;
		height: 52px;
		background: #e53b3c;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 21px;
		color: #ffffff;
		text-align: center;
		margin: 54px auto;
		line-height: 52px;
		border-radius: 4px;
	}

	/* 物流模块 */
	.three_four {
		width: 100%;
		height: 765px;
		position: relative;
	}

	.three_four_bg {
		width: 100%;
		height: 100%;
	}

	.three_four_content {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(255, 255, 255, 0.3);
	}

	.three_four_ms {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 30px;
		color: #ffffff;
		text-align: center;
	}

	.three_four_header {
		width: 1200px;
		height: 48px;
		margin: 60px auto 48px auto;
		background: rgba(22, 25, 34, 0.6);
	}

	.three_four_header_list {
		width: 33.333%;
		height: 100%;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 48px;
		color: #ffffff;
		text-align: center;
		float: left;
	}

	.three_four_header_list_now {
		background-color: #e53b3c;
	}

	.three_four_content_list {
		width: 1200px;
		overflow: hidden;
		margin: 0px auto;
		color: #fff;
		text-indent: 30px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 26px;
	}

	.three_four_content_list p {
		font-size: 18px;
		margin-top: 24px;
	}

	.three_four_content_btn {
		width: 470px;
		height: 48px;
		margin: 88px auto;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 48px;
		text-align: center;
	}

	.three_four_content_btn_l {
		width: 220px;
		height: 100%;
		background: rgba(255, 255, 255, 0.39);
		border-radius: 4px;
		color: #e53b3c;
		float: left;
	}

	.three_four_content_btn_r {
		width: 220px;
		height: 100%;
		background: #e53b3c;
		border-radius: 4px;
		color: #fff;
		float: right;
	}

	/* 仓储模块 */
	.three_five {
		width: 100%;
		height: 730px;
		position: relative;
	}

	.three_five_bg {
		width: 100%;
		height: 100%;
	}

	.three_five_content {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.three_five_header {
		width: 1200px;
		height: 48px;
		line-height: 48px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 48px;
		color: #ffffff;
		margin: 50px auto;
		text-align: center;
	}

	.three_five_header_l {
		width: 600px;
		height: 48px;
		background: #e53b3c;
		float: left;
	}

	.three_five_header_r {
		width: 600px;
		height: 48px;
		background: rgba(22, 25, 34, 0.39);
		float: right;
	}

	.three_five_one {
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
	}

	.three_five_one_list {
		width: 25%;
		height: 266px;
		float: left;
		text-align: center;
	}

	.three_five_one_list img {
		width: 88px;
		height: 88px;
		border-radius: 50%;
		margin-bottom: 16px;
		margin-top: 32px;
	}

	.three_five_one_list h6 {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 43px;
		color: #333333;
	}

	.three_five_one_list p {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 43px;
		color: #666666;
	}

	.three_five_one_list:hover {
		background-color: #fff;
		transform: scale(1.2);
		box-shadow: 0px 10px 16px rgba(170, 83, 29, 0.07);
		border-radius: 10px;
	}

	/* 计算器 */
	.three_six {
		width: 100%;
		height: 1216px;
		position: relative;
	}

	.three_six_bg {
		width: 100%;
		height: 100%;
	}

	.three_six_content {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.three_six_one {
		width: 1200px;
		overflow: hidden;
		margin: 72px auto;
	}

	.three_six_one_list {
		width: 33%;
		overflow: hidden;
		float: left;
		margin-bottom: 48px;
	}

	.three_six_one_list img {
		width: 60px;
		height: 65px;
		float: left;
	}

	.three_six_one_list_r {
		width: 217px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 24px;
		color: #666666;
		float: left;
		margin-left: 24px;
	}

	.three_six_one_list_r h3 {
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
	}

	.three_six_one_list_r p {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 24px;
		color: #666666;
		margin-top: 16px;
	}

	.three_six_two {
		width: 875px;
		height: 470px;
		margin: 88px auto;
		display: block;
	}

	/* 合作伙伴 */
	.three_seven {
		width: 100%;
		height: 644px;
	}

	.three_seven_one {
		width: 1200px;
		height: 140px;
		position: relative;
		margin: 50px auto;
	}

	.three_seven_one img {
		width: 100%;
		height: 100%;
	}

	.three_seven_one_content {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.three_seven_one_list {
		width: 20%;
		height: 100%;
		text-align: center;
		float: left;
	}

	.three_seven_one_list h3 {
		font-size: 34px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 45px;
		color: #333333;
		margin-top: 32px;
	}

	.three_seven_one_list p {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 30px;
		color: #666666;
	}

	.three_seven_two {
		width: 1200px;
		height: auto;
		margin: 20px auto;
	}

	.three_seven_two_list {
		width: 180px;
		height: 100px;
		background: rgba(255, 255, 255, 0.39);
		box-shadow: 0px 2px 16px rgba(114, 0, 0, 0.1);
		border-radius: 8px;
		text-align: center;
		margin-bottom: 24px;
		float: left;
		margin-right: 24px;
	}

	.three_seven_two_list:nth-child(6n + 6) {
		margin-right: 0;
	}

	.three_seven_two_list img {
		width: 136px;
		height: 64px;
		margin-top: 18px;
	}

	.header_xinpin {
		width: 1200px;
		height: 300px;
		/* border: 1px solid red; */
		margin: 0 auto;
		display: flex;
		margin-top: 14px;
		background: #ffffff;
	}

	.header_xinpin_tabars {
		width: 190px;

	}

	.header_xinpin_tabar {
		width: 160px;
		height: 100%;
		position: relative;
		margin-left: 47px;
		margin-right: 47px;
		margin-top: 16px;

		/* border: 1px solid #333333; */
	}

	.header_xinpin_tabar_image {
		height: 160px;
		width: 100%;
		/* margin-left: 47px; */
	}

	.header_xihuan {
		width: 1248px;
		/* height: 1400px; */
		/* border: 1px solid red; */
		margin: 0 auto;
		display: flex;
		margin-top: 6px;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 60px;
	}

	.header_xihuan_tabar {
		width: 284px;
		height: 100%;
		/* border: 1px solid #333333; */
		height: 366px;
		background-color: #ffffff;
		margin-left: 21px;
		margin-top: 20px;
		border-radius: 8px;

	}

	.header_xihuan_tabar_image {
		height: 220px;
		width: 220px;

		margin-left: 32px;
		margin-top: 32px;


	}

	::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #FF6C34 !important;
		color: #fff;
	}

	/* 选中后的字体颜色 */
	::v-deep .el-radio__input.is-checked+.el-radio__label {
		color: #e53b3c !important;
	}
</style>